export function CloseIcon() {
    return <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M2 2L13.25 13.25M24.5 24.5L13.25 13.25M13.25 13.25L24.5 2L2 24.5'
            stroke='currentColor'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
}
