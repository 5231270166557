import {useContext, useEffect, useState} from 'react'

import {AssistantContext} from './common/AssistantContextProvider'
import MediaContent from './common/MediaContent'
import Questions from './common/Questions'

import type {QuestionStatistics} from './common/Questions'
import type {Action, QuestionsAction} from './session/action'
import type {BookDB} from 'services/database'

type Props = {
    action: Action | undefined
    book: BookDB
    onFinish: (questionStatistics?: QuestionStatistics) => void
}

export default function Actions({action, book, onFinish}: Props) {
    const {play} = useContext(AssistantContext)
    const [mediaContent, setMediaContent] = useState<string>()
    const [showQuestions, setShowQuestions] = useState(false)
    const [showQuestionsMedia, setShowQuestionsMedia] = useState<{mediaContent: string, questionStatistics: QuestionStatistics}>()

    const clear = () => {
        setMediaContent(undefined)
        setShowQuestions(false)
        setShowQuestionsMedia(undefined)
    }

    useEffect(
        () => {
            switch (action?.type) {
            case 'media':
                play(action.assistant_begin)
                    .then(() => setMediaContent(action.media_url))
                break
            case 'questions':
                play(action.assistant_begin)
                    .then(() => setShowQuestions(true))
                break
            default:
                break
            }
        },
        [action, play]
    )

    if (!action)
        return null

    const handleClose = (props?: {skip?: boolean, questionStatistics?: QuestionStatistics}) => {
        setMediaContent(undefined)
        setShowQuestions(false)

        if (props?.skip) {
            onFinish(props.questionStatistics || showQuestionsMedia?.questionStatistics)
            clear()
            return
        }

        switch (action.type) {
        case 'media':
            play(action.assistant_success).then(() => {
                onFinish()
                clear()
            })
            break

        case 'questions':
            if (
                !showQuestionsMedia &&
                action.media_url &&
                props?.questionStatistics &&
                (!action.success_threshold || props.questionStatistics.correct / props.questionStatistics.total >= action.success_threshold)
            ) {
                setShowQuestionsMedia({mediaContent: action.media_url, questionStatistics: props.questionStatistics})
                return
            }

            play(action.assistant_success).then(() => {
                onFinish(props?.questionStatistics || showQuestionsMedia?.questionStatistics)
                clear()
            })
            break

        default:
            break
        }
    }

    if (mediaContent || showQuestionsMedia)
        return <MediaContent
            name={`/media/video/${showQuestionsMedia ? showQuestionsMedia.mediaContent : mediaContent}.webm`}
            onClose={handleClose}
        />

    if (showQuestions)
        return <Questions
            book={book}
            action={action as QuestionsAction}
            onClose={handleClose}
        />

    return null
}
