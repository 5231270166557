// import {user} from './devStore'
import {http} from './http'
import {BACKEND} from 'config'

type AuthResponse = {
    message: string
}

export type User = {
    username: string
}

export const auth = () => http.get<AuthResponse>(`${BACKEND}/auth`).then(({message}) => ({username: message}))

export const login = (data: {username: string, password: string}) => http.post(`${BACKEND}/login`, data)

export const logout = () => http.post(`${BACKEND}/logout`)

export const register = (data: {username: string, password: string, verifyPassword: string}) => http.post(`${BACKEND}/auth/registration`, data)

// Dev only

// export const auth = () => Promise.resolve<User>(user)

// export const login = (data: {username: string, password: string}) => data.username == 'admin' && data.password == 'password'
//     ? Promise.resolve()
//     : Promise.reject()

// export const logout = () => Promise.resolve()

