import clsx from 'clsx'
import {useLayoutEffect, useRef, useState} from 'react'

import type {AssistantContext} from './AssistantContextProvider'

// TODO: предусмотреть поддержку, когда будет для мака и для хрома разные сорсы

const getPathByName = (name: string, format: string) => `/media/assistant/${name}.${format}`

type Props = {
    setContext: (context: AssistantContext) => void
}

export type AssistantOptions = {
    style?: Record<string, string>
    blur?: boolean
    avaliableHide?: boolean
}

export default function Assistant({setContext}: Props) {
    const [visible, setVisible] = useState(false)
    const [outside, setOutside] = useState(false)
    const [options, setOptions] = useState<AssistantOptions>()
    const videoRef = useRef<HTMLVideoElement>(null)

    useLayoutEffect(() => {
        const videoElement = videoRef.current

        if (!videoElement)
            return

        // TODO: в React.StrictMode когда useEffect дважды запускаются - возникают ошибки.
        setContext({
            play: (src: string, options?: AssistantOptions) => new Promise(resolve => {
                setOptions(options)
                const reset = () => {
                    setVisible(false)
                    setOutside(false)
                    videoElement.removeAttribute('src')
                    videoElement.load()
                    resolve(null)
                }

                videoElement.addEventListener('play', () => setVisible(true), {once: true})
                videoElement.addEventListener('pause', reset, {once: true})
                videoElement.addEventListener('ended', reset, {once: true})

                videoElement.removeAttribute('src')
                videoElement.load()

                videoElement.src = getPathByName(src, videoElement.canPlayType('video/mp4; codecs="hvc1"') ? 'mov' : 'webm')
                videoElement.load()
                videoElement.play()
            }),
        })
    }, [setContext])

    useLayoutEffect(() => {
        if (videoRef.current) {
            const {width, height} = document.body.getBoundingClientRect()
            const {top, left, right, bottom} = videoRef.current.getBoundingClientRect()

            setOutside(!!options?.avaliableHide && (left < 0 || top < 0 || right > width || bottom > height))
        }
    }, [options, visible])

    const stop = () => {
        videoRef.current?.pause()
    }

    return (
        <div
            className={clsx(
                'assistant-overlay',
                visible && 'assistant-overlay_visible'
            )}
        >
            <div
                className={clsx('assistant-overlay__blur', options?.blur === false && 'assistant-overlay__blur_hidden')}
                onClick={() => outside ? stop() : undefined}
            />
            <video
                ref={videoRef}
                className={clsx('assistant', !options && 'assistant_default')}
                style={{
                    ...options?.style,
                    ...outside ? {visibility: 'hidden'} : {},
                }}
                onClick={stop}
                playsInline
            />
        </div>
    )
}
