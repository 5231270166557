export const splitIntoChunks = <T>(array: T[], callback: (item: T) => boolean): T[][] => {
    const result: T[][] = []
    let chunk: T[] = []

    array.forEach(item => {
        if (callback(item)) {
            chunk.push(item)
            result.push(chunk)
            chunk = []
        } else
            chunk.push(item)
    })

    if (chunk.length)
        result.push(chunk)

    return result
}
