import {useContext, useEffect} from 'react'

import LibraryItem from './LibraryItem'
import Content from 'components/common/Content'
import Screen from 'components/common/Screen'
import {StoreContext} from 'components/common/StoreContextProvider'
import Title from 'components/common/Title'

export default function Library() {
    const {library, updateLibrary} = useContext(StoreContext)

    useEffect(() => {
        updateLibrary()
    }, [updateLibrary])

    return (
        <Screen version='4'>
            <Content navigation scroll>
                <Title title='Библиотека'/>
                <div className='form form_main library'>
                    {library.map(item =>
                        <LibraryItem key={item.id} libraryItem={item}/>
                    )}
                </div>
            </Content>
        </Screen>
    )
}
