import {useContext, useEffect, useMemo} from 'react'
import {useNavigate} from 'react-router'

import Button from 'components/common/Button'
import Content from 'components/common/Content'
import Screen from 'components/common/Screen'
import Select from 'components/common/Select'
import {StoreContext} from 'components/common/StoreContextProvider'
import Title from 'components/common/Title'
import {logout} from 'services/login'
import {useOnline} from 'services/online'

import type {Option} from 'components/common/Select'
import type {Profile} from 'services/profile'

export default function Profile() {
    const isOnline = useOnline()
    const {profiles, profile, activateProfile, updateProfiles, updateUser} = useContext(StoreContext)
    const profilesMap = Object.fromEntries(profiles.map(profile => [profile.id, profile]))

    useEffect(() => {
        updateProfiles()
    }, [updateProfiles])

    const navigate = useNavigate()
    const handleLogout = () => {
        logout()
            .then(() => updateUser())
    }

    const edit = (id?: string) => navigate(`/profile/${id || 0}`)

    const options: Option[] = useMemo(
        () => [
            ...profiles
                .map(profile => ({name: profile.name, value: profile.id}))
                .sort((a, b) => a.name.localeCompare(b.name)),
        ] as Option[],
        [profiles]
    )

    const onEdit = (id?: string) => {
        navigate(`/profile/${id || 0}`)
    }

    const onChange = (option: Option) => {
        activateProfile(profilesMap[option.value])
    }

    const option = profile && {name: profile.name, value: profile.id}

    return (
        <Screen version='5'>
            <Content navigation>
                <Title title='Профиль'/>
                <div className='form col col_g-40 col_g-30-m col_w-100 col_center'>
                    <Select
                        options={options}
                        label='Профиль'
                        placeholder={options.length ? 'Выберите профиль' : 'Список профилей пуст'}
                        value={option}
                        onChange={onChange}
                        disabled={!options.length}
                        wide
                    />
                    <div className='row row_g-20 row_w-100 row_nowrap'>
                        <div className='row row_wide row_center'>
                            <Button
                                className='button button_link button_wide'
                                onClick={() => onEdit(option?.value)}
                                disabled={!option}
                                title='Настройки профиля'
                            />
                        </div>
                        <div className='row row_wide row_center'>
                            <Button
                                className='button button_link button_wide'
                                onClick={() => handleLogout()}
                                title='Войти в другой аккаунт'
                            />
                        </div>
                    </div>
                    <div className='row row_g-20 row_w-100 row_nowrap'>
                        <div className='row row_wide row_center'>
                            <Button
                                className='button button_filled button_wide'
                                disabled={!isOnline}
                                onClick={() => edit()}
                                title='Новый профиль'
                            />
                        </div>
                        <div className='row row_wide row_center'>
                            <Button
                                className='button button_outline button_wide'
                                onClick={() => navigate('/main')}
                                title='Главное меню'
                            />
                        </div>
                    </div>
                </div>
            </Content>
        </Screen>
    )
}
