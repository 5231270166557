import {Suspense, useContext, useEffect} from 'react'
import {Navigate, Route, BrowserRouter as Router, Routes} from 'react-router-dom'

import Loading from './components/loader/Loading'
import Login from './components/login/Login'
import Registeration from './components/register/Registeration'
import {StoreContext} from 'components/common/StoreContextProvider'
import {clearFullDatabase} from 'services/database'

export default function PublicRouter() {
    const {user, database, clearStore} = useContext(StoreContext)

    useEffect(() => {
        if (!database)
            return

        if (!user)
            clearFullDatabase(database).then(() => clearStore())
    }, [user, database, clearStore])

    if (user)
        return null

    return (
        <Router>
            <Suspense fallback={<Loading/>}/>
            <Routes>
                <Route path='/' element={<Navigate replace to='/login'/>}/>
                <Route path='/login' element={<Login/>}/>
                <Route path='/register' element={<Registeration/>}/>
                <Route path='/register/success' element={<Registeration/>}/>
                <Route path='/*' element={<Navigate replace to='/login'/>}/>
            </Routes>
        </Router>
    )
}
