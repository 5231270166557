import clsx from 'clsx'

type Props = {
    label: string
    wide: boolean
    onChange: (value: string) => void
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>

export default function Input({id, label, type, value, onChange, placeholder, wide}: Props) {
    return (
        <div className={clsx('control', wide && 'control_wide')}>
            <label htmlFor={id} className='control__label'>{label}</label>
            <input
                id={id}
                className='control__input'
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={event => onChange(event.target.value)}
                autoComplete='do-not-autofill'
                name={id}
            />
        </div>
    )
}
