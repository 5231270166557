import clsx from 'clsx'

import Navigation from './Navigation'

type Props = {
    children?: React.ReactNode
    navigation?: boolean
    scroll?: boolean
}

export default function Content({children, navigation, scroll}: Props) {
    return <div className={clsx(
        'col col_h-auto-m col_mh-100-m col_py-20 col_center',
        !scroll && 'col_h-100 col_mh-100vh',
        scroll && navigation && 'col_g-80'
    )}>
        {navigation && <Navigation/>}
        <div className={clsx('col col_g-80 col_g-40-m col_w-100 col_center', navigation && 'col_wide')}>
            {children}
        </div>
    </div>
}

