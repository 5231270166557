import {Suspense, useContext} from 'react'
import {Navigate, Route, BrowserRouter as Router, Routes} from 'react-router-dom'

import Library from './components/library/Library'
import Loading from './components/loader/Loading'
import Main from './components/main/Main'
import Profile from './components/profile/Profile'
import ProfileItem from './components/profile/ProfileItem'
import Session from './components/session/Session'
import SessionChecklist from './components/session/SessionChecklist'
import SessionSettings from './components/session/SessionSettings'
import {StoreContext} from 'components/common/StoreContextProvider'
import SessionStatistics from 'components/statistics/SessionStatistics'
import Statistics from 'components/statistics/Statistics'
import {SystemRoute} from 'components/system/SystemRoute'

export default function PrivateRouter() {
    const {user, profile} = useContext(StoreContext)

    if (!user)
        return null

    return (
        <Router>
            <Suspense fallback={<Loading/>}/>
            <Routes>
                <Route path='/*' element={profile ? <Navigate replace to='/main'/> : <Navigate replace to='/profile'/>}/>
                <Route path='/profile' element={<Profile/>}/>
                <Route path='/profile/:profileId' element={<ProfileItem/>}/>
                <Route path='/library' element={<Library/>}/>
                <Route path='/main' element={<Main/>}/>
                <Route path='/statistics' element={<Statistics/>}/>
                <Route path='/session/:bookId/settings' element={<SessionSettings/>}/>
                <Route path='/session/:bookId/statistics' element={<SessionStatistics/>}/>
                <Route path='/session/:bookId' element={<Session/>}/>
                <Route path='/session/settings' element={<SessionSettings/>}/>
                <Route path='/session/checklist' element={<SessionChecklist/>}/>
                <Route path='/_/:action' element={<SystemRoute/>}/>
                {/* <Route path='/*' element={<Navigate replace to='/main'/>}/> */}
            </Routes>
        </Router>
    )
}
