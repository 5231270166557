import clsx from 'clsx'
import {useState} from 'react'

import Button from './Button'
import {ArrowDownIcon} from 'components/icons/ArrowDownIcon'
import {CloseIcon} from 'components/icons/CloseIcon'

export type Option = {
    name: string
    value: string //TODO возможно стоит изменить типизацию так как value не обязательно должно быть string
}

type Props = {
    value: Option | undefined
    options: Option[]
    onChange: (selectedOption: Option) => void
    placeholder: string
    label: string
    disabled?: boolean
    wide?: boolean
    optionTemplate?: (option: Option) => JSX.Element
}

export default function Select({value, options, placeholder, label, disabled, onChange, wide, optionTemplate}: Props) {
    const [open, setOpen] = useState(false)

    const clickOutside = (event: React.MouseEvent) => {
        event.stopPropagation()
        setOpen(false)
    }

    const optionRender = optionTemplate
        ? optionTemplate
        : (option: Option) => <Button
            className='control__option'
            onClick={() => onChange(option)}
            title={option.name}
            key={option.value}
        />

    return <div className={clsx('control', wide && 'control_wide')}>
        <div className={clsx('control__label', disabled && 'control__label_disabled')}>
            {label}
        </div>
        <div className={clsx('control__select', disabled && 'control__select_disabled', !value && 'control__select_placeholder')}>
            <button
                onClick={() => setOpen(true)}
                disabled={disabled}
            >
                {value ? value.name : placeholder}
            </button>
            <ArrowDownIcon/>
        </div>
        {open &&
            <div
                className='control__overlay'
                onClick={clickOutside}
            >
                <div className='control__options'>
                    {options.map(option => optionRender(option))}
                </div>
                <div className='control__close'>
                    <CloseIcon/>
                </div>

            </div>
        }
    </div>
}
