import AssistantContextProvider from 'components/common/AssistantContextProvider'
import InstallDialog from 'components/common/InstallDialog'
import SessionContextProvider from 'components/common/SessionContextProvider'
import StoreContextProvider from 'components/common/StoreContextProvider'
import PrivateRouter from 'PrivateRouter'
import PublicRouter from 'PublicRouter'

function App() {
    return (
        <div className='app'>
            <StoreContextProvider>
                <SessionContextProvider>
                    <AssistantContextProvider>
                        <PublicRouter/>
                        <PrivateRouter/>
                    </AssistantContextProvider>
                </SessionContextProvider>
            </StoreContextProvider>
            <InstallDialog/>
        </div>
    )
}

export default App
