import {useContext, useEffect, useMemo, useState} from 'react'

import CommonStatistics from './CommonStatistics'
import TotalStatistics from './TotalStatistics'
import Navigation from 'components/common/Navigation'
import Screen from 'components/common/Screen'
import Select from 'components/common/Select'
import {StoreContext} from 'components/common/StoreContextProvider'
import Title from 'components/common/Title'
import {formatPercent, useBookStatistics} from 'services/statistics'

export default function Statistics() {
    const {profiles, library, bookKeys, profile, updateProfiles} = useContext(StoreContext)

    const [profileId, setProfileId] = useState<string>(profile?.id || '')
    const [bookId, setBookId] = useState<string>('total')
    const [statistics, ready] = useBookStatistics(profileId, bookId)

    const downloadedLibrary = useMemo(
        () => library.filter(({id, version}) => bookKeys.some(keys => keys.id == id && keys.version == version)),
        [bookKeys, library]
    )

    const profileOptions = profiles.map(profile => ({name: profile.name, value: profile.id}))
    const profileOption = profileOptions.find(option => option.value == profileId)
    const bookOptions = [
        {name: 'Общий прогресс обучения', value: 'total'},
        ...downloadedLibrary.map(book => ({name: book.short_title, value: book.id})),
    ]
    const bookOption = bookOptions.find(option => option.value == bookId)

    useEffect(() => {
        updateProfiles()
    }, [updateProfiles])

    return (
        <Screen>
            <div className='col col_h-auto-m col_mh-100-m col_py-20 col_g-80'>
                <Navigation/>
                <div className='col col_g-80 col_g-40-m col_w-100 col_wide col_x-center'>
                    <Title title='Статистика'/>
                    <div className='form col col_g-40 col_g-30-m col_w-100'>
                        <div className='col col_g-30 col_g-20-m col_w-100 text text_size-24'>
                            <Select
                                options={profileOptions}
                                label='Профиль'
                                placeholder='Выберите профиль'
                                value={profileOption}
                                onChange={option => setProfileId(option.value)}
                                wide
                            />
                            <Select
                                options={bookOptions}
                                label='Вариант'
                                placeholder={downloadedLibrary.length ? 'Выберите произведение' : 'Нет загруженных произведений в библиотеке'}
                                value={bookOption}
                                onChange={option => setBookId(option.value)}
                                wide
                            />
                            <div className='session-statistics col col_center'>
                                {!ready &&
                                    <div className='row row_center'>
                                        <span className='session-settings__spinner'/>
                                    </div>
                                }
                                {ready && !statistics &&
                                    <div className='text text_center'>Статистика отсутствует</div>
                                }
                                {ready && statistics &&
                                    <div className='session-statistics col col_g-15'>
                                        {bookId === 'total' &&
                                            <TotalStatistics statistics={statistics}/>
                                        }
                                        {bookId !== 'total' && <>
                                            <div className='row row_between row_g-10'>
                                                <span>Всего прочитано, слов:</span>
                                                <span className='text text_bolder'>{statistics.totalWords}</span>
                                            </div>
                                            <div className='row row_between row_g-10'>
                                                <span>Отслеживание:</span>
                                                <span className='text text_bolder'>
                                                    <span>{formatPercent(statistics.totalTrackedSuccess / statistics.totalTracked)}</span>
                                                </span>
                                            </div>
                                            <CommonStatistics statistics={statistics}/>
                                        </>}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Screen>
    )
}
