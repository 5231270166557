import clsx from 'clsx'
import {useLayoutEffect, useRef, useState} from 'react'

const defaultLimit = 70

type Props = {
    value: number
    limit?: number
}

export default function StatisticsProgress({value, limit = defaultLimit}: Props) {
    const [showValue, setShowValue] = useState(false)
    const prepareValue = Math.round(100 * value || 0)

    const ref = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        const updateSize = () => {
            if (!ref.current)
                return

            setShowValue(ref.current.getBoundingClientRect().width >= 50)
        }

        window.addEventListener('resize', updateSize)
        updateSize()
        return () => window.removeEventListener('resize', updateSize)
    }, [])

    return <div className={clsx('statistics-progress', prepareValue < limit && 'statistics-progress_red')}>
        <div ref={ref} style={{width: `${prepareValue}%`}} className='statistics-progress__value row row_center row_avaliable-empty'>
            {showValue && `${prepareValue}%`}
        </div>
    </div>
}
