/* eslint-disable no-console */
import clsx from 'clsx'
import {useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import BookContinueDialog from './BookContinueDialog'
import BookFooter from './BookFooter'
import BookPlayer from './BookPlayer'
import BookTitle from './BookTitle'
import Actions from 'components/Actions'
import {AssistantContext} from 'components/common/AssistantContextProvider'
import {StoreContext} from 'components/common/StoreContextProvider'
import {FONT_SIZE_MAP} from 'components/profile/ProfileItem'
import SessionFinish from 'components/session/SessionFinish'
import {defaultSessionSettings} from 'components/session/SessionSettings'
import {isDev} from 'config'
import {
    calculatePages,
    checkAction,
    defaultPageData,
    getPageData,
    getRandomChecks,
    getSpace,
    handleBookError,
    prepareRawText,
    proceedWord,
    pulseCountdown,
    skipActionsTillTime,
} from 'services/book'
import {mergeSearchString} from 'services/search'
import {successDelta} from 'services/statistics'
import {toggleWakeLock} from 'services/wakeLock'
import {delay} from 'utils/delay'

import type {BookPlayerRef} from './BookPlayer'
import type {AssistantOptions} from 'components/common/Assistant'
import type {Action} from 'components/session/action'
import type {SessionQueryParams} from 'components/session/Session'
import type {Book, PageData, Phrase, TempStatistics} from 'services/book'
import type {BookDB} from 'services/database'
import type {LibraryItem} from 'services/library'
import type {Profile} from 'services/profile'

const millisToSaveProgress = 5000
const pauseWhenPageChanges = 500

const defaultSessionCounterData = {timer: 0 as number | undefined, words: 0, start: 0, finish: 0}
type SessionCounterData = typeof defaultSessionCounterData
type Props = {
    book: BookDB
    settings: SessionQueryParams
    profile: Profile
    bookId: string
    description?: LibraryItem
}

//TODO заиспользовать playingWordRef для паузы и выделения

export default function Book({book, settings, profile, description, bookId}: Props) {
    const navigate = useNavigate()
    const {part} = settings
    const {skipCountdown, pauseOnPageChange, checkInterval, lostTimer, mode} = profile.sessionSettings || defaultSessionSettings
    const partAmount = +(description?.parts_amount || 0)

    const {updateTempStatistics, updateStatistics} = useContext(StoreContext)
    const {play: assistantPlay} = useContext(AssistantContext)

    const {mp3, json, id} = book
    const {actions: rawActions = [], text: rawText} = json
    const text = useMemo(
        () => prepareRawText(rawText),
        [rawText]
    )

    const localStorageKey = `${profile.id}-${id}-${part}-session-time`
    const checkMillisInterval = checkInterval ? +checkInterval * 1000 : undefined
    const lostMillisInterval = lostTimer ? +lostTimer * 1000 : undefined

    const containerRef = useRef<HTMLDivElement>(null)
    const pageData = useRef<PageData>(defaultPageData)
    const playerRef = useRef<BookPlayerRef>(null)
    const lostTimeoutRef = useRef<ReturnType<typeof setTimeout>>()
    const wordsRef = useRef<Record<string, HTMLSpanElement>>({})
    const pausedWordRef = useRef<HTMLSpanElement>()
    const touchTimeRef = useRef<{touchingTime: number | undefined}>({touchingTime: undefined})
    const audioTimeRef = useRef<{audioTime: number | undefined}>({audioTime: undefined})
    const mouseRef = useRef({leftButtonPressed: false})
    const sessionDurationRef = useRef<SessionCounterData>(defaultSessionCounterData)
    const stackActionCallbackRef = useRef<() => void>()
    const trackingData = useRef<Record<string, 'success' | 'mistake' | 'missed' | undefined>>({})

    const [playing, setPlaying] = useState(false)
    const [showFinish, setShowFinish] = useState(false)
    const [pages, setPages] = useState<Phrase[][][]>([])
    const [pageIndex, setPageIndex] = useState(0)
    const [inCountdown, setInCountdown] = useState(false)
    const [initialized, setInitialized] = useState(false)

    const [actionStack, setActionStack] = useState<Action[]>([])
    const [action, setAction] = useState<Action>()
    const [continueDialogTime, setContinueDialogTime] = useState<number>(0)
    const [startNow, setStartNow] = useState(false)
    const [randomCheckedPoints, setRandomCheckedPoints] = useState<number[]>([])
    const totalTime = useMemo(() => json.text.at(-1)?.at(-1)?.end || 0, [json.text])
    const url = useMemo(() => URL.createObjectURL(mp3), [mp3])

    const actionsAfter = useMemo(
        () => rawActions.filter(({after}) => after),
        [rawActions]
    )
    const enrichedActions = useMemo(
        () => [
            ...rawActions.filter(({after, before}) => !(after || before)),
            ...randomCheckedPoints.map(begin => ({begin, type: 'check'})),
        ] as Action[],
        [rawActions, randomCheckedPoints]
    )

    const finishSession = () => {
        resetDialogTime()
        navigate('main')
    }

    const handleUpdateStatistics = () => updateStatistics()
        .then(() => {
            resetLostTimeout()
            resetTrackingData()
        })

    const checkLost = () => {
        if ((audioTimeRef.current.audioTime || 0) - (touchTimeRef.current.touchingTime || 0) > successDelta)
            lostTimeoutRef.current ||= setTimeout(
                () => pause()
                    ?.then(() => assistantPlay(...getAssistantData('BehindPosition', pausedWordRef.current)))
                    .then(() => countdown(undefined, true)),
                lostMillisInterval
            )
        else
            resetLostTimeout()
    }

    const handlePause = () => {
        stopSessionCounter()
        updatePausedWord()
        setPlaying(false)
        proceedSaveProgress()

        return updateTempStatistics(getStatisticsData())
            .then(handleUpdateStatistics)
    }

    const play = (time?: number) => playerRef.current?.play(time)
        .then(() => {
            startSessionCounter(playerRef.current?.getTime())
            setPlaying(true)
        })

    const pause = () => playerRef.current?.pause()
        .then(handlePause)

    const isPaused = () => playerRef.current?.isPaused()
    const countdown = (time?: number, force = false) => {
        if (!force && skipCountdown)
            return play(time)

        if (isDev) {
            console.group('Countdown will start from')
            console.log('time', time)
            console.log('word', pausedWordRef.current)
            console.groupEnd()
        }

        setInCountdown(true)
        return pulseCountdown(
            pausedWordRef.current ||
            Object.entries(wordsRef.current).sort(([begin1], [begin2]) => +begin1 - +begin2)[0]?.[1]
        )
            .then(() => play(time))
            .finally(() => setInCountdown(false))
    }

    const proceedSaveProgress = () => {
        const millis = audioTimeRef.current.audioTime || 0

        if (millis > millisToSaveProgress && millis < totalTime - millisToSaveProgress)
            localStorage.setItem(localStorageKey, String(millis))
    }

    const proceedTimeUpdate = (millis: number, forceHighlight = false) => {
        checkAction(millis, enrichedActions, handleAction)
        proceedWord(millis, pageData.current, forceHighlight ? 'highlight' : mode, changePage)
        audioTimeRef.current.audioTime = millis
        proceedSaveProgress()
    }

    const changePage = (offset: number) => {
        setPageIndex(page => {
            const newPage = page + offset

            if (newPage >= 0 && newPage < pages.length)
                return newPage

            return page
        })

        if (pauseOnPageChange && !isPaused())
            pause()
                ?.then(() => delay(pauseWhenPageChanges))
                .then(() => play())
    }

    const previousTouchedNodeRef = useRef<HTMLElement>()
    const handleTracking = (x: number, y: number, tag = 'CITE') => {
        const trackedNode = document.elementsFromPoint(x, y)
            .find(({tagName}) => tagName === tag) as HTMLElement | undefined
        const wordElement = tag === 'CITE'
            ? trackedNode?.parentElement
            : trackedNode

        if (wordElement?.dataset.begin === undefined)
            previousTouchedNodeRef.current?.classList.remove('book__word_touched')
        else {
            touchTimeRef.current.touchingTime = +wordElement.dataset.begin
            trackWordByTouch(wordElement.dataset.begin)
            previousTouchedNodeRef.current?.classList.remove('book__word_touched')
            wordElement.classList.add('book__word_touched')
            previousTouchedNodeRef.current = wordElement
        }
    }

    const phraseClick = ({end}: Phrase) => {
        if (action?.type == 'check') {
            if (isDev) {
                console.group('action')
                console.log('clickedTime:', end)
                console.log('actionTime:', action.begin)
                console.groupEnd()
            }

            const fault = Math.abs(action.begin - end) > successDelta
            const assistant = fault ? 'CheckingPositionFail' : 'CheckingPositionSuccess'
            const actionResult = fault ? 'fault' : 'success'
            pausedWordRef.current?.classList.add('book__word_active')

            assistantPlay(...getAssistantData(assistant, pausedWordRef.current))
                .then(() => {
                    updateTempStatistics(getStatisticsData({historyItem: {actionData: action, actionResult}}))
                    setAction(undefined)
                    countdown()?.then(() => pausedWordRef.current?.classList.remove('book__word_active'))
                })
        }
    }

    const getStatisticsData = (
        {
            statistics,
            historyItem,
        }:
        {
            statistics?: Partial<TempStatistics>
            historyItem?: Partial<TempStatistics['history'][number]>
        } = {}
    ): TempStatistics => ({
        profileId: profile.id,
        bookId: book.id,
        part: book.part,
        version: book.version,
        sessionFinished: false,
        sessionPausedTime: continueDialogTime,
        mode,
        totalTime,
        sessionDuration: sessionDurationRef.current.finish - sessionDurationRef.current.start,
        sessionWordsCount: sessionDurationRef.current.words,
        questionStatistics: undefined,
        trackingData: trackingData.current,
        history: [{
            audioTime: audioTimeRef.current.audioTime,
            touchedTime: touchTimeRef.current.touchingTime,
            mode,
            skipCountdown: Boolean(skipCountdown),
            pauseOnPageChange: Boolean(pauseOnPageChange),
            ...historyItem,
        }],
        ...statistics,
    })

    const handleFinish = () => handlePause()
        .then(() => {
            if (actionsAfter.length) {
                stackActionCallbackRef.current = () => setShowFinish(true)
                setActionStack(actionsAfter)
                return
            }

            setShowFinish(true)
        })

    const handleTouch = ({changedTouches}: React.TouchEvent<HTMLDivElement>) => {
        const {clientX, clientY} = changedTouches.item(0)
        handleTracking(clientX, clientY)
    }

    const handleTouchEnd = () => {
        previousTouchedNodeRef.current?.classList.remove('book__word_touched')
    }

    const handleMouseDown = () => mouseRef.current.leftButtonPressed = true

    const handleMouseUp = () => {
        mouseRef.current.leftButtonPressed = false
        handleTouchEnd()
    }

    const handleMouseMove = ({clientX, clientY}: React.MouseEvent) => {
        if (mouseRef.current.leftButtonPressed)
            handleTracking(+clientX, +clientY, 'SPAN')
    }

    const handleAction = (action: Action) => {
        if (isDev)
            console.log('Обнаружено действие:', action)

        if (action.type != 'check')
            return pause()?.then(() => setAction(action))

        if (mode == 'check')
            return pause()
                ?.then(() => assistantPlay('CheckingPosition'))
                .then(() => setAction(action))
    }

    const handleTimeUpdate = (millis: number) => {
        if (mode === 'sound')
            checkLost()
        proceedTimeUpdate(millis)
        updateTempStatistics(getStatisticsData())
        trackWordByPlayer()
    }

    const resetDialogTime = () => {
        setContinueDialogTime(0)
        localStorage.removeItem(localStorageKey)
    }

    const resetLostTimeout = () => {
        clearTimeout(lostTimeoutRef.current)
        lostTimeoutRef.current = undefined
    }

    const resetTrackingData = () => {
        trackingData.current = {}
    }

    const updatePausedWord = (forceTime?: number) => {
        const time = forceTime === undefined
            ? audioTimeRef.current.audioTime
            : forceTime

        if (!containerRef.current || time === undefined)
            return handleBookError('updatePausedWord', containerRef.current, time)
        const words = Object.entries(wordsRef.current)
        const wordIndex = words.findIndex(([key]) => +key >= time)
        pausedWordRef.current = words[wordIndex]?.[1]
    }

    const getAssistantData = (assistantName: string, span?: HTMLSpanElement): [string, AssistantOptions | undefined] => {
        if (!span)
            return [assistantName, undefined]
        const assistantHeight = 20 //viewport %
        const {top, left, width, y} = span.getBoundingClientRect()

        if (top === 0 && left === 0)
            return [assistantName, undefined]

        const position = y / window.innerHeight < assistantHeight / 100
            ? 'under'
            : 'over'
        const assistant = position === 'under'
            ? `${assistantName}Up`
            : assistantName

        return [
            assistant,
            {
                blur: false,
                style: {
                    top: `${top}px`,
                    left: `${left}px`,
                    maxHeight: `${assistantHeight}vh`,
                    transform: `translate(calc(-50% + ${width / 2}px), ${position === 'over' ? -90 : 0}%)`,
                },
            },
        ]
    }

    const trackWordByTouch = (wordBegin: string) => {
        if (
            mode === 'sound' &&
            (!trackingData.current[wordBegin] || trackingData.current[wordBegin] === 'mistake') &&
            audioTimeRef.current.audioTime
        )
            trackingData.current[wordBegin] = Math.abs(audioTimeRef.current.audioTime - +wordBegin) < successDelta
                ? 'success'
                : 'mistake'
    }

    const trackWordByPlayer = () => {
        if (mode === 'sound' && audioTimeRef.current.audioTime) {
            const words: [string, HTMLSpanElement | undefined][] = Object.entries(wordsRef.current)
            const wordIndex = words.findIndex(([key]) => +key > (audioTimeRef.current.audioTime || 0))
            const {begin} = words[wordIndex - 1]?.[1]?.dataset || {}

            if (begin && !(begin in trackingData.current))
                trackingData.current[begin] = 'missed'
        }
    }

    const startSessionCounter = (time = 0) => {
        const millis = time * 1000
        sessionDurationRef.current.start = millis
        sessionDurationRef.current.finish = millis
        sessionDurationRef.current.timer ||= setInterval(
            () => sessionDurationRef.current.finish += 100,
            100
        ) as unknown as number
    }

    const stopSessionCounter = () => {
        const {start, finish, timer} = sessionDurationRef.current
        clearInterval(timer)
        sessionDurationRef.current.words = Object
            .keys(wordsRef.current)
            .filter(begin => +begin > start && +begin < finish)
            .length
        sessionDurationRef.current.timer = undefined
    }

    useLayoutEffect(
        () => {
            const getPages = () => setPages(calculatePages(text, containerRef.current))
            getPages()
            window.addEventListener('resize', getPages)
            return () => window.removeEventListener('resize', getPages)
        },
        [text]
    )

    useEffect(
        () => {
            if (initialized)
                return
            const time = localStorage.getItem(localStorageKey) as string | undefined

            if (time && isFinite(+time))
                setContinueDialogTime(+time)

            else
                setStartNow(true)

            setInitialized(true)
        },
        [mode, text, localStorageKey, initialized]
    )

    useEffect(
        () => setRandomCheckedPoints(getRandomChecks(text, undefined, checkMillisInterval)),
        [checkMillisInterval, text]
    )

    useEffect(
        () => {
            toggleWakeLock(true)

            return () => {
                toggleWakeLock(false)
            }
        },
        []
    )

    useEffect(
        () => () => clearInterval(sessionDurationRef.current.timer),
        []
    )

    useEffect(
        () => {
            if (startNow) {
                setStartNow(false)
                countdown()
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [startNow]
    )

    //TODO для отладок
    // useEffect(
    //     () => {
    //         console.group('Рандомные точки')
    //         console.log('точки:', randomCheckedPoints)
    //         console.groupEnd()
    //     },
    //     [randomCheckedPoints]
    // )

    return <div
        className='book'
        style={{'--profileFontSize': FONT_SIZE_MAP[profile.fontSize || 0]}}
        ref={containerRef}
        onTouchMove={handleTouch}
        onTouchEnd={handleTouchEnd}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseUp}
        onMouseUp={handleMouseUp}
    >
        <div
            className={clsx('book__page', json.general?.align && `book__page_align-${json.general.align}`)}
            ref={pageRef => {
                if (pageRef)
                    pageData.current = getPageData(pageRef)
            }}
        >
            {pages[pageIndex]?.map((phrase, i) =>
                <p key={i}>
                    {phrase.map((word, j) =>
                        <span
                            title={isDev ? String(word.begin) : undefined}
                            ref={word => {
                                if (word?.dataset.begin)
                                    wordsRef.current[word.dataset.begin] = word
                            }}
                            key={word.begin}
                            className={clsx('book__word', word.tag && `book__word_${word.tag.toLowerCase()}`)}
                            data-begin={word.begin}
                            data-end={word.end}
                            onClick={() => phraseClick(word)}
                        >
                            {word.word}{getSpace(word.word, phrase[j + 1]?.word)}
                            <cite
                                data-begin={word.begin}
                                data-end={word.end}
                                className='book__word_touch'
                            />
                        </span>
                    )}
                </p>
            )}
        </div>
        <div className='col col_nowrap col_w-100'>
            <BookTitle
                description={description}
                part={+part}
            />
            <BookFooter
                disabled={inCountdown}
                playing={playing}
                onPause={pause}
                onStart={countdown}
            />
            <BookPlayer
                ref={playerRef}
                src={url}
                onTimeUpdate={handleTimeUpdate}
                onEnd={handleFinish}
            />
        </div>
        <BookContinueDialog
            time={continueDialogTime}
            onContinue={time => {
                setContinueDialogTime(0)
                setPageIndex(pages.findIndex(pagePhrases => pagePhrases.flat().some(({begin}) => begin >= time)))
                setTimeout(() => {
                    const closestWordBegin = Object.keys(wordsRef.current).find(key => +key >= time)

                    if (!closestWordBegin)
                        return handleBookError('BookContinueDialog -> onContinue', wordsRef.current, closestWordBegin)

                    const continueTime = +closestWordBegin
                    skipActionsTillTime(enrichedActions, continueTime)
                    updatePausedWord(continueTime)
                    proceedTimeUpdate(continueTime)
                    if (mode == 'check')
                        setRandomCheckedPoints(getRandomChecks(text, continueTime, checkMillisInterval))
                    countdown(continueTime, true)
                })
            }}
            onReset={() => {
                resetDialogTime()
                proceedTimeUpdate(0, true)
            }}
        />
        {actionStack[0] &&
            <Actions
                action={actionStack[0]}
                book={book}
                onFinish={questionStatistics => {
                    if (questionStatistics)
                        updateTempStatistics(getStatisticsData({statistics: {questionStatistics}}))
                            .then(handleUpdateStatistics)
                    if (actionStack.length > 1)
                        setActionStack(stack => stack.toSpliced(0, 1))
                    else {
                        setActionStack([])
                        stackActionCallbackRef.current?.()
                    }
                }}
            />
        }
        <Actions
            action={action}
            book={book}
            onFinish={questionStatistics => {
                if (questionStatistics)
                    updateTempStatistics(getStatisticsData({statistics: {questionStatistics}}))
                        .then(handleUpdateStatistics)
                setAction(undefined)
                countdown()
            }}
        />
        <SessionFinish
            isNextPart={+part < partAmount}
            visible={showFinish}
            onHide={() => setShowFinish(false)}
            onFinish={finishSession}
            onRepeat={() => {
                navigate(`/session/${bookId}/settings`)
                localStorage.removeItem(localStorageKey)
            }}
            onNext={() => {
                navigate(`/session/${bookId}/${mergeSearchString({part: +part + 1})}`)
                resetDialogTime()
                location.reload()
            }}
        />
    </div>
}
