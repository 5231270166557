/* eslint-disable no-console */
import {openDB} from 'idb'
import {createContext, useCallback, useEffect, useState} from 'react'

import SyncDialog from './syncDialog'
import {isDev} from 'config'
import {clearFullDatabase, readDatabase, synchronizationToBackend, synchronizationToDatabase} from 'services/database'
import {getLibrary, getLibraryItemJson, getLibraryItemMp3, getLibraryItemQuestionMp3, getLibraryItemQuestionsJson} from 'services/library'
import {auth, type User} from 'services/login'
import {shouldSyncKey, useOnline} from 'services/online'
import {activateProfile, addProfile, deleteProfile, getProfiles, updateProfile} from 'services/profile'
import {
    type Statistics,
    calculateStatistics,
    updateBookStatistics,
    denormalizeStatistics,
    deletePartStatistics,
    getBookStatistics as getBookStatisticsFromBack,
} from 'services/statistics'
import {getUniq} from 'services/uniqArray'

import type {LoadingStatus} from 'components/library/LibraryItem'
import type {IDBPDatabase} from 'idb'
import type {Book, TempStatistics} from 'services/book'
import type {BookDB, QuestionDB, QuestionDescription, StoreDatabase, SynchronizationProps} from 'services/database'
import type {LibraryItem} from 'services/library'
import type {Profile} from 'services/profile'

export type BookKey = {
    id: string
    version: string
    part: string
}

export type QuestionKey = {
    id: string
    version: string
    question: string
}

export type StatisticsKey = {
    profileId: string
    bookId: string
    part: string
}
type UpdateProfile = ({profile, action}: {profile: Profile, action: 'add' | 'delete' | 'update'}) => Promise<unknown>
type UpdateProfiles = () => Promise<void>
type ActivateProfile = (profile: Profile) => Promise<unknown>
type UpdateLibrary = () => Promise<void>
type UpdateStatistics = () => Promise<unknown>
type GetStatistics = (profileId: string, bookId: string, part: string) => Promise<Statistics | undefined>
type GetBookStatistics = (profileId: string, bookId: string) => Promise<Statistics[] | undefined>
type DeleteStatistics = (profileId: string, bookId: string, part: string) => Promise<unknown>

type UpdateBook = (
    props:
        {
            id: string
            action: 'delete'
        } |
        {
            id: string
            partsAmount: number
            questionsAmount: number
            version: string
            action: 'load'
            skipRef: React.MutableRefObject<boolean>
            callback: (state: Partial<LoadingStatus>) => void
        }
) => Promise<unknown>

type GetBook = (props: {id: string, part: string, version: string}) => Promise<BookDB | undefined>
type GetQuestion = (props: {id: string, version: string, question: string}) => Promise<QuestionDB | undefined>

export type StoreContext = {
    user: User | undefined
    updateUser: () => void
    profiles: Profile[]
    updateProfiles: UpdateProfiles
    updateProfile: UpdateProfile
    profile: Profile | undefined
    activateProfile: ActivateProfile
    library: LibraryItem[]
    updateLibrary: UpdateLibrary
    updateStatistics: UpdateStatistics
    getStatistics: GetStatistics
    getBookStatistics: GetBookStatistics
    deleteStatistics: DeleteStatistics
    updateTempStatistics: (statistics: TempStatistics) => Promise<void>
    getTempStatistics: () => Promise<TempStatistics | undefined>
    deleteTempStatistics: () => Promise<void>
    bookKeys: BookKey[]
    updateBook: UpdateBook
    getBook: GetBook
    questionDescriptions: QuestionDescription[]
    questionKeys: QuestionKey[]
    getQuestion: GetQuestion
    database: IDBPDatabase<StoreDatabase> | undefined
    clearStore: () => void
}

export const StoreContext = createContext<StoreContext>({
    user: undefined,
    updateUser: () => undefined,
    profiles: [],
    updateProfiles: () => Promise.resolve(),
    updateProfile: () => Promise.resolve(),
    profile: undefined,
    activateProfile: () => Promise.resolve(),
    library: [],
    updateLibrary: () => Promise.resolve(),
    updateStatistics: () => Promise.resolve(),
    getStatistics: () => Promise.resolve(undefined),
    getBookStatistics: () => Promise.resolve([]),
    deleteStatistics: () => Promise.resolve(),
    updateTempStatistics: () => Promise.resolve(),
    getTempStatistics: () => Promise.resolve(undefined),
    deleteTempStatistics: () => Promise.resolve(),
    bookKeys: [],
    updateBook: () => Promise.resolve(),
    getBook: () => Promise.resolve(undefined),
    questionKeys: [],
    questionDescriptions: [],
    getQuestion: () => Promise.resolve(undefined),
    database: undefined,
    clearStore: () => undefined,
})

type Props = {
    children?: React.ReactNode
}

export default function StoreContextProvider({children}: Props) {
    const isOnline = useOnline()
    const [database, setDatabase] = useState<IDBPDatabase<StoreDatabase>>()
    const [ready, setReady] = useState(false)
    const [user, setUser] = useState<User>()
    const [profiles, setProfiles] = useState<Profile[]>([])
    const [profile, setProfile] = useState<Profile>()
    const [library, setLibrary] = useState<LibraryItem[]>([])
    const [bookKeys, setBookKeys] = useState<BookKey[]>([])
    const [questionKeys, setQuestionKeys] = useState<QuestionKey[]>([])
    const [questionDescriptions, setQuestionDescriptions] = useState<QuestionDescription[]>([])
    const [syncCallback, setSyncCallback] = useState<() => Promise<unknown>>()

    const clearStore = useCallback(() => {
        setUser(undefined)
        setProfiles([])
        setProfile(undefined)
        setLibrary([])
        setBookKeys([])
        setQuestionKeys([])
        setQuestionDescriptions([])
    }, [])

    useEffect(() => {
        openDB<StoreDatabase>('database', 1, {
            // Инициализация/миграция базы данных
            upgrade: (database, oldVersion/*, newVersion, transaction*/) => {
                switch (oldVersion) {
                case 0:
                    database.createObjectStore('session')
                    database.createObjectStore('library', {keyPath: 'id'})
                    database.createObjectStore('profiles', {keyPath: 'id'})
                    database.createObjectStore('books', {keyPath: ['id', 'part', 'version']})
                    database.createObjectStore('questionDescriptions', {keyPath: ['id', 'version']})
                    database.createObjectStore('questions', {keyPath: ['id', 'version', 'question']})
                    database.createObjectStore('statistics', {keyPath: ['profileId', 'bookId', 'part']})
                    database.createObjectStore('tempStatistics')
                    break
                default:
                    console.error('unknown db version')
                }
            },
        })
            .then(database => {
                setDatabase(database)

                return readDatabase(database)
                    .then(({username, profileId, profiles, library, bookKeys, questionsKeys, questionDescriptions}) => {
                        setUser(username ? {username} : undefined)
                        setProfiles(profiles)
                        setProfile(profiles.find(profile => profile.id == profileId))
                        setLibrary(library)
                        setBookKeys(bookKeys.map(([id, part, version]) => ({id, part, version})))
                        setQuestionDescriptions(questionDescriptions)
                        setQuestionKeys(questionsKeys.map(([id, version, question]) => ({id, version, question})))
                    })
            })
            .catch(() => {
                // alert('Ошибка инициализации базы данных, приложение будет перезапущено с очитской данных')
                window.indexedDB.deleteDatabase('database')

                setTimeout(() => window.location.reload(), 100)

                setDatabase(undefined)
            })
            .finally(() => setReady(true))
    }, [])

    // Синхрониация если интернет включили во время использования приложения
    useEffect(() => {
        if (!isOnline || !database || !ready)
            return

        const shouldSync = localStorage.getItem(shouldSyncKey)

        if (!shouldSync)
            return

        auth()
            .then(user => readDatabase(database)
                .then(databaseProps => {
                    if (databaseProps.username == user.username)
                        setSyncCallback(() => () => synchronizationToBackend(databaseProps, database))
                    else
                        clearFullDatabase(database)
                            .then(() => clearStore())
                })
            )
            .catch(() => clearFullDatabase(database).then(() => clearStore()))
            .finally(() => localStorage.removeItem(shouldSyncKey))
    }, [isOnline, database, ready, clearStore])

    useEffect(
        () => {
            if (database && ready && isOnline) {
                console.log('обновляю данные с сервера...')
                Promise.all([
                    syncStatistics(),
                    syncProfiles(),
                ])
                    .then(() => console.log('данные успешно обновлены'))
                    .catch(error => console.log('ошибка:', error))
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ready]
    )

    const updateLibrary: UpdateLibrary = useCallback(
        () => database && isOnline
            ? getLibrary()
                .then(library =>
                    synchronizationToDatabase(
                        database,
                        {library}
                    )
                        .then(() => setLibrary(library))
                )
            : Promise.resolve(),
        [database, isOnline]
    )

    const activateProfileHandler: ActivateProfile = useCallback(
        profile => {
            if (!database)
                return Promise.resolve()

            return database.put('session', profile.id || '', 'profileId')
                .then(() => setProfile(profile))
                .then(() => activateProfile(profile.id).catch(console.error))
        },
        [database]
    )

    async function syncProfiles() {
        if (!database || !isOnline)
            return

        const {profiles, activeId} = await getProfiles()
        setProfiles(profiles)
        const activeProfile = profiles.find(({id}) => id === activeId)

        if (activeId && activeProfile)
            setProfile(activeProfile)
    }

    const updateProfiles: UpdateProfiles = useCallback(
        () => database && isOnline
            ? getProfiles()
                .then(({profiles, activeId}) => {
                    const data: Partial<SynchronizationProps> = {profiles}
                    let updateActiveProfileOnServer = false

                    if (!profile && activeId)
                        data.profileId = activeId
                    if (profile && activeId && profile.id != activeId)
                        updateActiveProfileOnServer = true

                    return synchronizationToDatabase(
                        database,
                        !profile && activeId
                            ? {profiles, profileId: activeId}
                            : {profiles}
                    )
                        .then(() => setProfiles(profiles))
                        .then(() => updateActiveProfileOnServer && profile
                            ? activateProfile(profile.id)
                                .then()
                            : Promise.resolve()
                        )
                })
            : Promise.resolve(),
        [database, isOnline, profile]
    )

    const getBookStatistics = useCallback(
        async(profileId: string, bookId: string) => {
            if (!database)
                return
            const books = await database.getAll('library').then(books => getUniq(books, 'id'))
            const profileStatistics = isOnline
                ? await Promise.all(books.map(({id}) => getBookStatisticsFromBack(profileId, id)))
                    .then(statistics => statistics
                        .flat()
                        .filter(item => item.profileId == profileId)
                    )
                : await database.getAll('statistics')
                    .then(statistics => statistics.filter(item => item.profileId == profileId))

            if (bookId === 'total')
                return profileStatistics.filter(item => books.some(({id}) => item.bookId === id))

            return profileStatistics.filter(item => item.bookId === bookId)
        },
        [database, isOnline]
    )

    async function syncStatistics() {
        if (!database)
            return

        const books = await database.getAll('library').then(books => getUniq(books, 'id'))
        return Promise.all(
            profiles.flatMap(({id: profileId}) => books.map(({id: bookId}) => getBookStatisticsFromBack(profileId, bookId)))
        )
            .then(statistics => statistics.flat())
            .then(statistics => Promise.all(statistics.map(item => database.put('statistics', item))))
    }

    if (!ready || !database)
        return null

    const updateProfileHandler: UpdateProfile = ({profile, action}) => {
        switch (action) {
        case 'add':
            return addProfile(profile)
                .then(profile => database.put('profiles', profile)
                    .then(() => setProfiles([...profiles, profile]))
                    .then(() => activateProfileHandler(profile))
                )
        case 'delete':
            return deleteProfile(profile.id)
                .then(() => database.delete('profiles', profile.id))
                .then(() => setProfiles(profiles.filter(({id}) => id == profile.id)))
                .then(() => database.get('session', 'profileId'))
                .then(activeId => {
                    if (activeId === profile.id)
                        if (profiles.length)
                            activateProfileHandler(profiles[0])
                        else
                            setProfile(undefined)
                })
        case 'update':
            return database.put('profiles', profile)
                .then(() => {
                    setProfiles(profiles.map(profileItem => profileItem.id == profile.id ? profile : profileItem))
                    setProfile(profile)
                    updateProfile(profile)
                })
        }
    }

    // Логин/Логаут
    const updateUser = () => {
        console.log('update user...')
        auth()
            .then(user => Promise.all([
                getProfiles(),
                getLibrary(),
            ])
                .then(([{profiles, activeId}, library]) => synchronizationToDatabase(
                    database,
                    {
                        profiles,
                        username: user.username,
                        profileId: activeId || '',
                        library,
                    }
                )
                    .then(() => {
                        setUser(user)
                        setProfiles(profiles)
                        setProfile(activeId
                            ? profiles.find(profile => profile.id == activeId)
                            : undefined
                        )
                        setLibrary(library)
                    })
                )
            )
            .then(() => console.log('user updated'))
            .catch(error => {
                // eslint-disable-next-line no-console
                console.log('что-то пошло не так', error)
                return clearFullDatabase(database).then(() => clearStore())
            })
    }

    // ...database.getAllKeys('books')
    // .then(data => data
    //     .map(([id, part, version]) => ({id, part, version}))
    //     .filter(item => item.id == props.id)
    //     .map(bookKey => database.delete('books', [bookKey.id, bookKey.part, bookKey.version]))
    // ),

    // Promise.all([database.getAllKeys('books'), ])

    const updateBook: UpdateBook = props => {
        const deletePromise = () =>
            readDatabase(database, ['books', 'questions', 'questionDescriptions'])
                .then(({bookKeys, questionsKeys, questionDescriptions}) => ({
                    bookKeys: bookKeys.map(([id, part, version]) => ({id, part, version})),
                    questionKeys: questionsKeys.map(([id, version, question]) => ({id, version, question})),
                    questionDescriptions,
                }))
                .then(({bookKeys, questionKeys, questionDescriptions}) =>
                    Promise.all([
                        ...bookKeys
                            .filter(item => item.id == props.id)
                            .map(({id, part, version}) => database.delete('books', [id, part, version])),
                        ...questionKeys
                            .filter(item => item.id == props.id)
                            .map(({id, version, question}) => database.delete('questions', [id, version, question])),
                        ...questionDescriptions
                            .filter(item => item.id == props.id)
                            .map(({id, version}) => database.delete('questionDescriptions', [id, version])),
                    ])
                )

        const setStoreBookKeys = (keys: [string, string, string][]) => {
            setBookKeys(keys.map(([id, part, version]) => ({id, part, version})))
        }

        const updateStorePromise = () => Promise.all([
            database.getAllKeys('books'),
            database.getAll('questionDescriptions'),
        ])
            .then(([bookKeys, questionDescriptions]) => {
                setStoreBookKeys(bookKeys)
                setQuestionDescriptions(questionDescriptions)
            })

        switch (props.action) {
        case 'delete':
            return deletePromise()
                .then(() => updateStorePromise())
        case 'load': {
            const loadPartPromise = (part: number): Promise<void> => {
                if (part < props.partsAmount) {
                    const i = `${part + 1}`
                    return Promise.resolve()
                        .then(() => props.callback({state: `Загружается ${part + 1} часть`}))
                        .then(() => props.skipRef.current ? Promise.reject({skip: true}) : Promise.resolve())

                        .then(() => getLibraryItemMp3(props.id, i)
                            .then(mp3 => {
                                props.callback({mp3: part + 1})
                                return mp3
                            })
                        )
                        .then(mp3 => props.skipRef.current ? Promise.reject({skip: true}) : Promise.resolve(mp3))
                        .then(mp3 => getLibraryItemJson(props.id, i)
                            .then(json => {
                                props.callback({json: part + 1})
                                return [mp3, json]
                            })
                        )
                        .then(([mp3, json]) => database.put('books', {
                            id: props.id,
                            part: i,
                            version: props.version,
                            json: json as Book,
                            mp3: mp3 as Blob,
                        }))
                        .catch(({skip} = {}) => {
                            props.callback(skip
                                ? {state: 'Отмена загрузки'}
                                : {error: `Ошибка загрузки ${part + 1} части`}
                            )

                            return Promise.reject(i)
                        })
                        .then(() => loadPartPromise(part + 1))
                }

                return Promise.resolve()
            }

            const loadQuestionPromise = (question: number): Promise<void> => {
                if (question < props.questionsAmount) {
                    const questionId = `${question + 1}`

                    return Promise.resolve()
                        .then(() => props.skipRef.current ? Promise.reject({skip: true}) : Promise.resolve())
                        .then(() => props.callback({state: `Загружается ${questionId} вопрос`}))
                        .then(() => getLibraryItemQuestionMp3(props.id, questionId))
                        .then(mp3 => {
                            props.callback({question: question + 1})
                            return mp3
                        })
                        .then(mp3 => database.put('questions', {
                            id: props.id,
                            version: props.version,
                            question: questionId,
                            mp3,
                        }))
                        .catch(({skip} = {}) => {
                            props.callback(skip
                                ? {state: 'Отмена загрузки'}
                                : {error: `Ошибка загрузки ${questionId} вопроса`}
                            )

                            return Promise.reject(question)
                        })
                        .then(() => loadQuestionPromise(question + 1))
                }

                return Promise.resolve()
            }

            const loadQuestionDescriptionPromise = () => props.questionsAmount
                ? Promise.resolve()
                    .then(() => props.callback({state: 'Загружается список вопросов'}))
                    .then(() => getLibraryItemQuestionsJson(props.id)
                        .then(json => database.put('questionDescriptions', {
                            id: props.id,
                            version: props.version,
                            json,
                        }))
                    )
                    .then(() => props.callback({questions: 1}))
                    .catch(({skip} = {}) => {
                        props.callback(skip
                            ? {state: 'Отмена загрузки'}
                            : {error: 'Ошибка загрузки списка вопросов'}
                        )

                        return Promise.reject()
                    })

                : Promise.resolve()

            return deletePromise()
                .then(() => loadPartPromise(0))
                .then(() => loadQuestionDescriptionPromise())
                .then(() => loadQuestionPromise(0))
                .then(() => updateStorePromise())
                .catch(() => deletePromise())
        }
        }
    }

    const getBook: GetBook = ({id, part, version}) => database.get('books', [id, part, version])
    const getQuestion: GetQuestion = ({id, version, question}) => database.get('questions', [id, version, question])

    const updateStatistics: UpdateStatistics = async() => {
        const tempStatistics = await getTempStatistics()

        if (!tempStatistics)
            return

        const {profileId, bookId, part: bookPart} = tempStatistics
        return getStatistics(profileId, bookId, bookPart)
            .then(existStatistics => calculateStatistics(tempStatistics, existStatistics))
            .then(statistics => Promise.all([
                database.put('statistics', statistics),
                updateBookStatistics(denormalizeStatistics(statistics))
                    .catch(console.error),
                deleteTempStatistics(),
            ]))
            .catch(console.error)
    }

    const getStatistics: GetStatistics = (profileId, bookId, part) =>
        database.get('statistics', [profileId, bookId, part])

    const deleteStatistics: DeleteStatistics = (profileId, bookId, part) =>
        database.delete('statistics', [profileId, bookId, part])
            .then(() => deletePartStatistics(profileId, bookId, part))
            .catch(console.error)

    const updateTempStatistics = async(statistics: TempStatistics) => {
        const existHistory = await database.get('tempStatistics', 'tempStatistics').then(exist => exist?.history)
        database.put(
            'tempStatistics',
            {...statistics, history: [...existHistory || [], ...statistics.history]},
            'tempStatistics'
        )
            .catch(console.error)
    }

    const getTempStatistics = () =>
        database.get('tempStatistics', 'tempStatistics')

    const deleteTempStatistics = () =>
        database.delete('tempStatistics', 'tempStatistics')

    return <StoreContext.Provider value={{
        user, updateUser,
        profiles, updateProfiles,
        profile, updateProfile: updateProfileHandler, activateProfile: activateProfileHandler,
        library, updateLibrary,
        updateStatistics, getStatistics, deleteStatistics, getBookStatistics,
        updateTempStatistics, getTempStatistics, deleteTempStatistics,
        bookKeys,
        updateBook,
        getBook,
        questionDescriptions,
        questionKeys,
        getQuestion,
        database,
        clearStore,
    }}>
        {children}
        <SyncDialog
            onSync={syncCallback}
            setSync={setSyncCallback}
        />
    </StoreContext.Provider>
}

