const defaultSettings: RequestInit = {
    mode: 'cors',
    credentials: 'include',
}

const throwError = (error: unknown) => {
    // alert(JSON.stringify(error))
    throw error
}

function populateSearchParams(searchParamMap: Record<string, unknown>, searchParams = new URLSearchParams) {
    return Object
        .entries(searchParamMap)
        .reduce(
            (searchParams, [name, value]) => {
                if (value !== undefined)
                    for (const singleValue of [value].flat())
                        searchParams.append(name, `${singleValue}`)

                return searchParams
            },
            searchParams,
        )
}

function getUrl(url: string | URL, searchParamMap = {}) {
    url = new URL(url)
    populateSearchParams(searchParamMap, url.searchParams)
    return `${url}`
}

function setRequestContentType(requestData: RequestInit) {
    const contentType = requestData.body instanceof FormData
        ? undefined
        : 'application/json'

    if (contentType)
        ((requestData.headers ||= {}) as Record<string, string>)['Content-Type'] ||= contentType
}

function request<T>(url: string | URL, searchParams = {}, settings: RequestInit = {}, blob = false) {
    url = getUrl(url, searchParams)

    return fetch(url, {...defaultSettings, ...settings})
        .then(response => (blob ? response.blob() : response.json())
            .then((result: T) => ({result, ok: response.ok}))
            .catch(() => ({result: {}, ok: response.ok})),
        )
        .then(({result, ok}) => ok
            ? <T>result
            : throwError({error: result}),
        )
}

function get<T>(url: string | URL, searchParams = {}, settings: RequestInit = {}, blob = false) {
    return request<T>(url, searchParams, {...settings, method: 'GET'}, blob)
}

function patch<T>(url: string | URL, body = {}, searchParams = {}, settings: RequestInit = {}) {
    const requestData = {
        ...settings,
        body: body instanceof FormData
            ? body
            : JSON.stringify(body),
        method: 'PATCH',
    }

    setRequestContentType(requestData)

    return request<T>(url, searchParams, requestData)
}

function post<T>(url: string | URL, body = {}, searchParams = {}, settings: RequestInit = {}, blob = false) {
    const requestData = {
        ...settings,
        body: body instanceof FormData
            ? body
            : JSON.stringify(body),
        method: 'POST',
    }

    setRequestContentType(requestData)

    return request<T>(url, searchParams, requestData, blob)
}

function put<T>(url: string | URL, body = {}, searchParams = {}, settings: RequestInit = {}) {
    const requestData = {
        ...settings,
        body: body instanceof FormData
            ? body
            : JSON.stringify(body),
        method: 'PUT',
    }

    setRequestContentType(requestData)

    return request<T>(url, searchParams, requestData)
}

function _delete<T>(url: string | URL, searchParams = {}, settings: RequestInit = {}) {
    return request<T>(url, searchParams, {...settings, method: 'DELETE'})
}

export const http = {
    request,
    get,
    patch,
    post,
    put,
    delete: _delete,
}
