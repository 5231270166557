import {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import CommonStatistics from './CommonStatistics'
import {defaultSessionQueryParams} from '../session/Session'
import Button from 'components/common/Button'
import Content from 'components/common/Content'
import Screen from 'components/common/Screen'
import {StoreContext} from 'components/common/StoreContextProvider'
import Title from 'components/common/Title'
import {getLibrary} from 'services/library'
import {useSearchParams} from 'services/search'
import {prepareStatistics, type Statistics} from 'services/statistics'

import type {SessionQueryParams} from '../session/Session'
import type {LibraryItem} from 'services/library'

export default function SessionStatistics() {
    const {part, version} = useSearchParams(defaultSessionQueryParams) as SessionQueryParams
    const {profile, getStatistics, deleteStatistics} = useContext(StoreContext)
    const {bookId} = useParams()
    const [statistics, setStatistics] = useState<Statistics>()
    const [book, setBook] = useState<LibraryItem>()
    const navigate = useNavigate()

    const updateData = useCallback(
        () => {
            if (profile?.id && bookId) {
                getStatistics(profile.id, bookId, part).then(setStatistics)
                getLibrary().then(library => setBook(library.find(({id, version: _version}) => id == bookId && version == _version)))
            }
        },
        [bookId, getStatistics, part, profile?.id, version]
    )

    useEffect(updateData, [updateData])

    const statisticsData = useMemo(
        () => prepareStatistics(statistics),
        [statistics]
    )

    const statisticsValid = profile?.id && bookId && statisticsData

    return <Screen version='1'>
        <Content scroll>
            <Title title='Статистика сессии'/>
            {!statisticsValid && <div className='text'>Статистика отсутствует</div>}
            <div className='form form session-settings'>
                <div className='col col_g-30 col_g-20-m col_w-100 text text_size-24'>
                    {statisticsValid &&
                        <div className='col col_g-20'>
                            <div className='col col_g-10'>
                                <div className='row row_between row_g-10'>
                                    <span>Книга:</span>
                                    <span className='text text_right text_bolder'>{book?.short_title || book?.title}</span>
                                </div>
                                <div className='row row_between row_g-10'>
                                    <span>Часть:</span>
                                    <span className='text text_bolder'>{part}</span>
                                </div>
                            </div>
                            <div className='col col_g-10'>
                                <div className='row row_between row_g-10'>
                                    <span>Всего прочитано, слов:</span>
                                    <span className='text text_bolder'>{statisticsData.totalWords}</span>
                                </div>
                                <div className='row row_between row_g-10'>
                                    <span>Всего прослушено, секунд:</span>
                                    <span className='text text_bolder'>
                                        <span>{statisticsData.totalListened}% </span>
                                        <span>({statisticsData.readLength}&nbsp;/&nbsp;{statisticsData.bookLength})</span>
                                    </span>
                                </div>
                                <div className='row row_between row_g-10'>
                                    <span>Отслеживание:</span>
                                    <span className='text text_bolder'>
                                        <span>{statisticsData.followedSuccess}% </span>
                                        <span>({statisticsData.totalFollowedSuccess}&nbsp;/&nbsp;{statisticsData.totalFollowed})</span>
                                    </span>
                                </div>
                            </div>
                            <CommonStatistics statistics={statisticsData}/>
                        </div>
                    }
                    <div className='row row_g-20 row_w-100 row_nowrap'>
                        <div className='row row_wide row_center'>
                            <Button
                                className='button button_outline button_wide'
                                onClick={() => navigate(-1)}
                                title='Назад'
                            />
                        </div>
                        <div className='row row_wide row_center'>
                            <Button
                                className='button button_filled button_wide'
                                onClick={() => profile?.id && bookId && deleteStatistics(profile.id, bookId, part).then(updateData)}
                                disabled={!statisticsValid}
                                title='Очистить статистику'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    </Screen>
}
