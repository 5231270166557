import {createContext, useEffect, useState} from 'react'

export type SessionContext = {
    isActive: boolean
    showGreeting: boolean
    setShowGreeting: (value: boolean) => void
}

export const SessionContext = createContext<SessionContext>({
    isActive: false,
    showGreeting: true,
    setShowGreeting: () => undefined,
})

type Props = {
    children?: React.ReactNode
}

export default function SessionContextProvider({children}: Props) {
    const [isActive, setIsActive] = useState<boolean>(false)
    const [showGreeting, setShowGreeting] = useState<boolean>(true)

    // TODO: подумать - есть ли еще события которые считаются пользовательским взаимодействием.
    useEffect(() => {
        const removeEventListeners = () => {
            document.removeEventListener('click', interactionHadler)
            document.removeEventListener('touchstart', interactionHadler)
        }

        const interactionHadler = () => {
            setIsActive(true)
            removeEventListeners()
        }

        document.addEventListener('click', interactionHadler, {once: true})
        document.addEventListener('touchstart', interactionHadler, {once: true})

        return removeEventListeners
    }, [])

    return <SessionContext.Provider value={{isActive, showGreeting, setShowGreeting}}>
        {children}
    </SessionContext.Provider>
}

