import {http} from './http'
import {checkShouldSync} from './online'
import {BACKEND} from 'config'

import type {ModeId} from './devStore'

export type Profile = {
    id: string
    name: string
    age?: number
    fontSize?: number
    sessionSettings?: {
        mode: ModeId
        checkInterval: number
        lostTimer: number
        pauseOnPageChange: boolean
        skipCountdown: boolean
    }
}

type ServerProfile = {
    id: number
    account_id: number
    is_deleted: boolean
    is_active: boolean
    profile_settings: ProfileSettings | null
}

type ProfileSettings = {
    id: string
    profile_name: string
    profile_age?: number
    font_family?: string
    font_size?: number
    background?: string
    check_interval: number
    lost_timer: number
    mode: ModeId
    pause_on_page_change: boolean
    skip_countdown: boolean
}

const defaultSessionSettings = {
    checkInterval: 60,
    lostTimer: 10,
    mode: 'highlight',
    pauseOnPageChange: false,
    skipCountdown: false,
} as const

const denormalizeProfileSettings = (profile: Profile): ProfileSettings => {
    const {checkInterval, lostTimer, mode, pauseOnPageChange, skipCountdown} = profile.sessionSettings || defaultSessionSettings
    return {
        id: profile.id,
        profile_name: profile.name,
        profile_age: profile.age,
        font_family: '',
        font_size: profile.fontSize,
        background: '',
        check_interval: checkInterval,
        lost_timer: lostTimer,
        mode,
        pause_on_page_change: pauseOnPageChange,
        skip_countdown: skipCountdown,
    }
}

const normalizeServerProfile = (profile: ServerProfile): Profile => {
    const {profile_settings: settings} = profile
    const {checkInterval, lostTimer, mode, pauseOnPageChange, skipCountdown} = defaultSessionSettings
    return {
        id: `${profile.id}`,
        name: settings?.profile_name || '',
        age: settings?.profile_age,
        fontSize: settings?.font_size,
        sessionSettings: {
            checkInterval: settings?.check_interval || checkInterval,
            lostTimer: settings?.lost_timer || lostTimer,
            mode: settings?.mode || mode,
            pauseOnPageChange: settings?.pause_on_page_change || pauseOnPageChange,
            skipCountdown: settings?.skip_countdown || skipCountdown,
        },
    }
}

export const getProfiles = () =>
    http.get<ServerProfile[]>(`${BACKEND}/profiles`)
        .then(profiles => ({
            profiles: profiles.map(normalizeServerProfile),
            activeId: profiles.find(profile => profile.is_active)?.id.toString(),
        }))

export const updateProfile = (profile: Profile): Promise<Profile> =>
    http.patch<ServerProfile>(`${BACKEND}/profiles/${profile.id}`, denormalizeProfileSettings(profile))
        .then(normalizeServerProfile)
        .catch(checkShouldSync<Profile>)

export const addProfile = (profile: Profile) =>
    http.post<ServerProfile>(`${BACKEND}/profiles`, denormalizeProfileSettings(profile))
        .then(profile => normalizeServerProfile(profile))

export const deleteProfile = (profileId: string) => http.delete<Profile>(`${BACKEND}/profiles/${profileId}`)

export const activateProfile = (profileId: string) => http.get<ServerProfile>(`${BACKEND}/profiles/${profileId}/activate`)
    .then(profile => normalizeServerProfile(profile))
    .catch(checkShouldSync<Profile>)

// Dev only
// export const getProfiles = () => Promise.resolve(profiles)
// export const updateProfile = (profile: Profile) => Promise.resolve(profiles.map(item => profile.id == item.id ? profile : item))
// export const tryUpdateProfile = (profile: Profile) => window.navigator.onLine
//     ? Promise.resolve(profile)
//     : Promise.resolve(profile)

// export const addProfile = (profile: Profile) => window.navigator.onLine
//     ? Promise.resolve({...profile, id: Date.now().toString()})
//     : Promise.resolve(profile)

// export const deleteProfile = (profileId: string) => window.navigator.onLine
//     ? Promise.resolve(true)
//     : Promise.resolve()

// export const activateProfile = (profileId: string) => window.navigator.onLine
//     ? Promise.resolve(true)
//     : Promise.resolve()
