import Button from 'components/common/Button'

type Props = {
    visible: boolean
    isNextPart: boolean
    onHide: () => void
    onNext: () => void
    onFinish: () => void
    onRepeat: () => void
}

export default function SessionFinish({onNext, onFinish, onHide, onRepeat, visible, isNextPart}: Props) {
    if (!visible)
        return null

    return (
        <div className='overlay'>
            <div className='col col_center col_g-30 menu'>
                {isNextPart &&
                    <Button
                        className='button button_outline button_w-100'
                        onClick={() => {
                            onNext()
                            onHide()
                        }}
                        title='Продолжить'
                    />
                }
                <Button
                    className='button button_outline button_w-100'
                    onClick={() => {
                        onFinish()
                        onHide()
                    }}
                    title='Завершить'
                />
                <Button
                    className='button button_outline button_w-100'
                    onClick={() => {
                        onRepeat()
                        onHide()
                    }}
                    title='Повторить'
                />
            </div>
        </div>
    )
}
