/* eslint-disable max-len */

import type {LibraryItem} from './library'
import type {User} from './login'
import type {Profile} from './profile'
import type {Action} from 'components/session/action'

export const user: User = {
    username: 'admin',
}

export const profiles: Profile[] = [{
    id: '1',
    name: 'Александр Пушкин',
    age: 37,
    fontSize: 14,
}, {
    id: '2',
    name: 'Вова',
    age: 4,
    fontSize: 18,
}]

export type BookData = {
    actions: Action[]
    text: {word: string, begin: number, end: number}[][]
}

export type Book = {
    id: string
    cover: string
    author: string
    name: string
    data: BookData
    url: string
}

export type ModeId = 'highlight' | 'sound' | 'check'

export type ModeOption = {
    id: ModeId
    name: string
}

export const MODES: ModeOption[] = [{
    id: 'highlight',
    name: 'Читаем с подсказкой',
}, {
    id: 'sound',
    name: 'Следим пальчиком',
}, {
    id: 'check',
    name: 'Следим глазками',
}]

export const DEV_LIBRARY: LibraryItem[] = [
    {
        author: 'Евгения Шмелёва',
        title: 'Евгения Шмелёва. Сборник стихов "Велосипедист".',
        short_title: 'Евгения Шмелёва. Сборник стихов "Велосипедист".',
        version: '2024-05-04T22:14:30.723391',
        id: 'evgeniya_shmeleva_stikhi',
        short_info: 'Сборник стихотворений Евгении Шмелёвой: Велосипедист. Я мечтаю. Маленький архитектор. Лето. и др.',
        parts_amount: 1,
        questions_amount: 16,
        speech_tempo: 53.0,
        duration_ms: 1086124.0,
        reader: 'Татьяна Солнцева',
        part_names: [
            'Велосипедист. Я мечтаю. Маленький архитектор. Лето. и др.',
        ],
    },
    {
        author: 'Пётр Павлович Ершов',
        title: 'Конёк-горбунок',
        short_title: 'П.П.Ершов, Конёк-горбунок',
        version: '2024-05-04T22:09:53.355570',
        id: 'konyok_gorbunok_v3',
        short_info: 'В сказке «Конёк-горбунок» рассказано о приключениях младшего сына крестьянина, Иванушки, и его верного друга, конька «с двумя горбами да с аршинными ушами». Царь посылал Ивана добыть для него Жар-птицу и Царь-девицу. Тот смог исполнить это благодаря помощи умного конька. Верный горбунок помог Ивану избежать гибели. В конце сказки Иван становится царём и женится на Царь-девице.',
        parts_amount: 21,
        questions_amount: 30,
        speech_tempo: 97.0,
        duration_ms: 6135163.0,
        reader: 'Татьяна Солнцева',
        part_names: [
            'Часть 1. Фрагмент 1.',
            'Часть 1. Фрагмент 2.',
            'Часть 1. Фрагмент 3.',
            'Часть 1. Фрагмент 4.',
            'Часть 1. Фрагмент 5.',
            'Часть 1. Фрагмент 6.',
            'Часть 2. Фрагмент 1.',
            'Часть 2. Фрагмент 2.',
            'Часть 2. Фрагмент 3.',
            'Часть 2. Фрагмент 4.',
            'Часть 2. Фрагмент 5.',
            'Часть 2. Фрагмент 6.',
            'Часть 2. Фрагмент 7.',
            'Часть 2. Фрагмент 8.',
            'Часть 3. Фрагмент 1.',
            'Часть 3. Фрагмент 2.',
            'Часть 3. Фрагмент 3.',
            'Часть 3. Фрагмент 4.',
            'Часть 3. Фрагмент 5.',
            'Часть 3. Фрагмент 6.',
            'Часть 3. Фрагмент 7.',
        ],
    },
    {
        author: 'Кристина Стрельникова',
        title: 'Кристина Стрельникова. Сборник стихов "Бегемот".',
        short_title: 'Кристина Стрельникова. Сборник стихов "Бегемот".',
        version: '2024-05-04T22:15:46.813870',
        id: 'kristina_strelnikova_stikhi',
        short_info: 'Сборник стихотворений для детей. Летела собака. Я повстречала Бегемота. Снежинка. Мобильник. Невоспитанный Боря. Вишнёвая липа. Потерянный платок. Я однажды завёл хомяка... Разговор с Тараканом. Лошадь. Четыре храбрых короля. Тёплая история. Формула мурлыканья.',
        parts_amount: 1,
        questions_amount: 16,
        speech_tempo: 51.0,
        duration_ms: 1132997.0,
        reader: 'Татьяна Солнцева',
        part_names: [
            'Летела собака. Я повстречала Бегемота. и др.',
        ],
    },
    {
        author: 'Людмила Громова',
        title: 'Людмила Громова. Сборник стихов "Сороконожки".',
        short_title: 'Людмила Громова. Сборник стихов "Сороконожки".',
        version: '2024-05-04T22:11:06.439599',
        id: 'lyudmila_gromova_stikhi',
        short_info: 'Мой дед. Сапожки для сороконожки. Малина (скороговорка). Арбуз. Лужа. Пылесос. Цветок. Большая клубника. Хомячки. Сторож. Подружки. Родня. Гурман. Встреча. Хор. Мечта. Гусеница. Божья коровка. Стрекоза. Бабочка. Пчела. Муравей. Жук. Муха. Считалка для слона. Буквоед.',
        parts_amount: 1,
        questions_amount: 15,
        speech_tempo: 51.0,
        duration_ms: 1077579.0,
        reader: 'Татьяна Солнцева',
        part_names: [
            'Мой дед, Сапожки для сороконожки и др.',
        ],
    },
    {
        author: 'В обработке Афанасьева Александра Николаевича.',
        title: 'Русские народные сказки. Часть 1.',
        short_title: 'Русские народные сказки. Часть 1.',
        version: '2024-05-04T22:11:31.428511',
        id: 'russk_narod_skazki_1',
        short_info: 'Гуси-лебеди. Сестрица Алёнушка и братец Иванушка. Емеля-дурак. Морской царь и Василиса Премудрая. Несмеяна-царевна.',
        parts_amount: 8,
        questions_amount: 20,
        speech_tempo: 109.0,
        duration_ms: 2552692.0,
        reader: 'Татьяна Солнцева',
        part_names: [
            'Гуси-лебеди.',
            'Сестрица Аленушка, братец Иванушка.',
            'Емеля-дурак.',
            'Морской царь и Василиса Премудрая. Часть 1.',
            'Морской царь и Василиса Премудрая. Часть 2.',
            'Морской царь и Василиса Премудрая. Часть 3.',
            'Морской царь и Василиса Премудрая. Часть 4.',
            'Несмеяна-царевна.',
        ],
    },
    {
        author: 'В обработке Афанасьева Александра Николаевича.',
        title: 'Русские народные сказки. Часть 2.',
        short_title: 'Русские народные сказки. Часть 2.',
        version: '2024-05-04T22:11:55.216351',
        id: 'russk_narod_skazki_2',
        short_info: 'Елена Премудрая. Царевна-лягушка. Баба-яга. Чудесная рубашка.',
        parts_amount: 8,
        questions_amount: 20,
        speech_tempo: 112.0,
        duration_ms: 2471758.0,
        reader: 'Татьяна Солнцева',
        part_names: [
            'Елена Премудрая. Часть 1.',
            'Елена Премудрая. Часть 2.',
            'Елена Премудрая. Часть 3.',
            'Царевна-лягушка. Часть 1.',
            'Царевна-лягушка. Часть 2.',
            'Баба-яга.',
            'Чудесная рубашка. Часть 1.',
            'Чудесная рубашка. Часть 2.',
        ],
    },
    {
        author: 'В обработке Афанасьева Александра Николаевича.',
        title: 'Русские народные сказки. Часть 3.',
        short_title: 'Русские народные сказки. Часть 3.',
        version: '2024-05-04T22:12:13.211883',
        id: 'russk_narod_skazki_3',
        short_info: 'Сказка о молодильных яблоках и живой воде. Хитрая наука.',
        parts_amount: 10,
        questions_amount: 20,
        speech_tempo: 113.0,
        duration_ms: 2490060.0,
        reader: 'Татьяна Солнцева',
        part_names: [
            'Сказка о молодильных яблоках и живой воде. Часть 1.',
            'Сказка о молодильных яблоках и живой воде. Часть 2.',
            'Сказка о молодильных яблоках и живой воде. Часть 3.',
            'Сказка о молодильных яблоках и живой воде. Часть 4.',
            'Сказка о молодильных яблоках и живой воде. Часть 5.',
            'Сказка о молодильных яблоках и живой воде. Часть 6.',
            'Сказка о молодильных яблоках и живой воде. Часть 7.',
            'Хитрая наука. Часть 1.',
            'Хитрая наука. Часть 2.',
            'Хитрая наука. Часть 3.',
        ],
    },
    {
        author: 'Александр Сергеевич Пушкин',
        title: 'Сказка о царе Салтане, о сыне его славном и могучем богатыре князе Гвидоне Салтановиче и о прекрасной царевне Лебеди',
        short_title: 'А.С.Пушкин, Сказка о царе Салтане',
        version: '2024-05-04T22:13:13.932971',
        id: 'skazka_o_tsare_saltane_v3',
        short_info: 'Она рассказывает о царе Салтане, который после того, как его жена родила сына, был обманут завистницами, которые изгнали царицу и ее сына на необитаемый остров. Но благодаря помощи волшебницы, царица и ее сын оказываются спасенными и возвращаются на свой престол, где ждет их счастливая встреча с царем Салтаном.',
        parts_amount: 8,
        questions_amount: 10,
        speech_tempo: 92.0,
        duration_ms: 2588869.0,
        reader: 'Татьяна Солнцева',
        part_names: [
            'Сказка о царе Салтане. Часть 1.',
            'Сказка о царе Салтане. Часть 2.',
            'Сказка о царе Салтане. Часть 3.',
            'Сказка о царе Салтане. Часть 4.',
            'Сказка о царе Салтане. Часть 5.',
            'Сказка о царе Салтане. Часть 6.',
            'Сказка о царе Салтане. Часть 7.',
            'Сказка о царе Салтане. Часть 8.',
        ],
    },
]
