import {useEffect, useState} from 'react'
import {useCookies} from 'react-cookie'

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[]

    readonly userChoice: Promise<{
      outcome: 'accepted' | 'dismissed'
      platform: string
    }>

    prompt(): Promise<void>

}

declare global {
    interface Navigator {
        getInstalledRelatedApps: () => Promise<never>
    }
}

export default function InstallDialog() {
    const [cookies, setCookie] = useCookies()
    const [installPromptEvent, setInstallPromptEvent] = useState<BeforeInstallPromptEvent>()

    // TODO: Добавить определение - установленно ли уже приложение, не задействуя
    // не работает API, даже в одном из качественных демо https://gira-same-domain.glitch.me/pwa/
    // useEffect(() => {
    //     if ('getInstalledRelatedApps' in navigator)
    //         navigator.getInstalledRelatedApps()
    // }, [])

    useEffect(() => {
        const initBeforeInstallPromptEvent: EventListener = event => {
            event.preventDefault()
            setInstallPromptEvent(event as BeforeInstallPromptEvent)
        }

        addEventListener('beforeinstallprompt', initBeforeInstallPromptEvent)

        return () => removeEventListener('beforeinstallprompt', initBeforeInstallPromptEvent)
    }, [])

    if (!installPromptEvent || cookies['hide-install-dialog'])
        return null

    const install = async() => {
        installPromptEvent.prompt()
            .then(() => {
                setInstallPromptEvent(undefined)
                setCookie('hide-install-dialog', true)
            })
    }

    const close = () => {
        setInstallPromptEvent(undefined)
        setCookie('hide-install-dialog', true)
    }

    return <div className='install-dialog col col_g-20 col_g-15-m'>
        <button
            className='button button_filled install-dialog__button'
            onClick={install}>Установить как приложение</button>
        <button
            className='button button_link'
            onClick={close}
        >
                скрыть
        </button>
    </div>
}
