import {createContext, useCallback, useState} from 'react'

import Assistant from './Assistant'

import type {AssistantOptions} from './Assistant'

export type AssistantContext = {
    play: (name: string, options?: AssistantOptions) => Promise<null>
}

export const AssistantContext = createContext<AssistantContext>({
    play: () => Promise.resolve(null),
})

type Props = {
    children?: React.ReactNode
}

export default function AssistantContextProvider({children}: Props) {
    const [context, setContext] = useState<AssistantContext>({
        play: () => Promise.resolve(null),
    })

    const setContextCallback = useCallback((context: AssistantContext) => {
        setContext(context)
    }, [setContext])

    return <AssistantContext.Provider value={context}>
        {children}
        <Assistant setContext={setContextCallback}/>
    </AssistantContext.Provider>
}

