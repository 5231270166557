import MainButton from './MainButton'
import Content from 'components/common/Content'
import Screen from 'components/common/Screen'
import Title from 'components/common/Title'

export default function Main() {
    return (
        <Screen version='3' copyright>
            <Content>
                <Title title='Главное Меню'/>
                <div className='form form_main col col_g-20 col_w-100'>
                    <div className='main'>
                        <MainButton title='Библиотека' path='/library' modifier='library'/>
                        <MainButton title='Обучение' path='/session/settings' modifier='training'/>
                        <MainButton title='Профили' path='/profile' modifier='profiles'/>
                        <MainButton title='Статистика' path='/statistics' modifier='statistics'/>
                    </div>
                </div>
            </Content>
        </Screen>
    )
}
