/* eslint-disable no-restricted-syntax */
import {useState, useEffect, useContext, useMemo, useRef, useLayoutEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {MODES} from '../../services/devStore'
import {AssistantContext} from 'components/common/AssistantContextProvider'
import Button from 'components/common/Button'
import Content from 'components/common/Content'
import Input from 'components/common/Input'
import Screen from 'components/common/Screen'
import Select from 'components/common/Select'
import {SessionContext} from 'components/common/SessionContextProvider'
import {StoreContext} from 'components/common/StoreContextProvider'
import Title from 'components/common/Title'
import {isDev} from 'config'
import {useDebounce} from 'services/debounce'
import {mergeSearchString, useSearchParams} from 'services/search'
import {useSimpleStorage} from 'services/storage'

import type {ModeId} from '../../services/devStore'
import type {Profile} from 'services/profile'

export const defaultSessionSettings = {
    mode: MODES[0].id,
    pauseOnPageChange: false,
    checkInterval: 60,
    lostTimer: 5,
    skipCountdown: false,
}

export default function SessionSettings() {
    const {bookId: ongoingBookId} = useParams()
    const {
        part: ongoingPart,
        version: ongoingVersion,
        initBookId,
    } = useSearchParams({
        mode: '',
        part: '',
        version: '',
        initBookId: '',
    })
    const {library, bookKeys, profile, updateProfile} = useContext(StoreContext)
    const {play} = useContext(AssistantContext)
    const {isActive, showGreeting, setShowGreeting} = useContext(SessionContext)

    const downloadedLibrary = useMemo(
        () => library.filter(({id, version}) => bookKeys.some(keys => keys.id == id && keys.version == version)),
        [bookKeys, library]
    )
    const initBook = downloadedLibrary.find(book => book.id == initBookId)

    const localStorageBookId = `session-book-id-data-${profile?.id}`
    const localStorageBookPart = `session-book-part-data-${profile?.id}`
    const localStorageBookVersion = `session-book-version-data-${profile?.id}`

    const [bookId, setBookId] = useSimpleStorage(
        localStorageBookId,
        ongoingBookId || initBook?.id || downloadedLibrary[0]?.id
    )
    const [part, setPart] = useSimpleStorage(
        localStorageBookPart,
        ongoingPart || '1'
    )
    const [version, setVersion] = useSimpleStorage(
        localStorageBookVersion,
        ongoingVersion || initBook?.version || downloadedLibrary[0]?.version || ''
    )

    useEffect(
        () => {
            const savedBookExists = downloadedLibrary.find(({id, version: existVersion}) => bookId === id && version === existVersion)

            if (!savedBookExists) {
                // eslint-disable-next-line no-console
                isDev && console.log('сработал сброс книги так как сохранённая книга не нашлась')
                setBookId(downloadedLibrary[0]?.id)
                setVersion(downloadedLibrary[0]?.version)
            }
        },
        [bookId, downloadedLibrary, version, setBookId, setVersion]
    )

    const {sessionSettings = defaultSessionSettings} = profile || {}
    const [mode, setMode] = useState(sessionSettings.mode)
    const [pauseOnPageChange, setPauseOnPageChange] = useState(sessionSettings.pauseOnPageChange)

    const [rawCheckInterval, checkInterval, setCheckInterval] = useDebounce<number | string>(sessionSettings.checkInterval, 300)
    const [rawLostTimer, lostTimer, setLostTimer] = useDebounce<number | string>(sessionSettings.lostTimer, 300)

    const [skipCountdown] = useState(sessionSettings.skipCountdown)
    const navigate = useNavigate()

    const partsOptions = useMemo(
        () => {
            const book = library.find(item => item.id == bookId)

            return Array.from(
                {length: book?.parts_amount || 0},
                (_, index) => {
                    const value = index + 1

                    return {
                        value: `${value}`,
                        name: `${book ? book.part_names[index] : value}`,
                    }
                }
            )
        },
        [bookId, library]
    )

    useEffect(
        () => {
            if (isActive && showGreeting) {
                setShowGreeting(false)
                play('Intro')
            }
        },
        [play, isActive, showGreeting, setShowGreeting]
    )

    const onSelectBook = (bookId: string) => {
        setBookId(bookId)
        setPart('1')
        setVersion(library.find(book => book.id == bookId)?.version || '')
    }

    const onSelectPart = (part: string) => {
        setPart(part)
    }

    const optionBooks = downloadedLibrary.map(book => ({name: book.short_title, value: book.id}))
    const selectedBook = optionBooks.find(option => option.value == bookId)
    const selectedPart = partsOptions.find(option => option.value == part)
    const modeOptions = MODES.map(mode => ({name: mode.name, value: mode.id}))
    const selectedMode = modeOptions.find(option => mode == option.value)

    //TODO убрать после апдейта типизации Select
    const pauseOnPageChangeOptions = [
        {value: false as unknown as string, name: 'Нет'},
        {value: true as unknown as string, name: 'Да'},
    ]
    const pauseOnPageChangeOption = pauseOnPageChangeOptions.find(option => option.value == pauseOnPageChange as unknown as string)

    const onSubmit = () => {
        if (!profile?.id)
            alert('Проблема с авторизацией')

        navigate(`/session/${bookId}/${mergeSearchString({
            mode,
            part,
            version,
        })}`)
    }

    const touched = useRef(false)

    function touch<T>(params: T, callback: (params: T) => unknown) {
        touched.current = true
        callback(params)
    }

    useLayoutEffect(
        () => {
            if (!touched.current)
                return

            updateProfile({
                profile: {
                    ...profile,
                    sessionSettings: {
                        mode,
                        checkInterval: checkInterval === '' ? defaultSessionSettings.checkInterval : checkInterval,
                        lostTimer: lostTimer === '' ? defaultSessionSettings.lostTimer : lostTimer,
                        pauseOnPageChange,
                        skipCountdown,
                    },
                } as Profile,
                action: 'update',
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [checkInterval, lostTimer, mode, pauseOnPageChange, skipCountdown]
    )

    return (
        <Screen version='2'>
            <Content navigation>
                <Title title='Настройки сессии'/>
                <div className='form col col_g-40 col_g-30-m col_w-100 col_center'>
                    <div className='col col_g-30 col_g-20-m col_w-100'>
                        <Select
                            options={optionBooks}
                            label='Произведение'
                            placeholder={downloadedLibrary.length ? 'Выберите произведение' : 'Нет загруженных произведений в библиотеке'}
                            value={selectedBook}
                            onChange={option => onSelectBook(option.value)}
                            disabled={!downloadedLibrary.length || Boolean(ongoingBookId)}
                        />
                        <Select
                            options={partsOptions}
                            label='Часть произведения'
                            placeholder={selectedBook ? 'Выберите часть' : 'Произведение не выбрано'}
                            value={selectedPart}
                            onChange={option => onSelectPart(option.value)}
                            disabled={!bookId}
                        />
                        <Select
                            options={modeOptions}
                            label='Режим'
                            placeholder='Выберите режим'
                            value={selectedMode}
                            onChange={option => touch(option.value as ModeId, setMode)}
                        />
                        {mode === 'check' &&
                            <Input
                                label='Спрашивать каждые, сек'
                                id='field_1'
                                value={rawCheckInterval}
                                placeholder={String(defaultSessionSettings.checkInterval)}
                                type='number'
                                onChange={interval => touch(interval, setCheckInterval)}
                                wide
                            />
                        }
                        {mode === 'sound' &&
                            <Input
                                label='Потеря фокуса, сек'
                                id='field_2'
                                value={rawLostTimer}
                                placeholder={String(defaultSessionSettings.lostTimer)}
                                type='number'
                                onChange={timer => touch(timer, setLostTimer)}
                                wide
                            />
                        }
                        <Select
                            options={pauseOnPageChangeOptions}
                            label='Паузы при перелистывании'
                            placeholder='Выберите'
                            value={pauseOnPageChangeOption}
                            onChange={option => touch(option.value as unknown as boolean, setPauseOnPageChange)}
                        />
                    </div>
                    <div className='row row_g-20 row_w-100 row_nowrap'>
                        <div className='row row_wide row_center'>
                            <Button
                                className='button button_outline button_wide'
                                onClick={() => navigate(-1)}
                                title='Назад'
                            />
                        </div>
                        <div className='row row_wide row_center'>
                            <Button
                                className='button button_filled button_wide'
                                onClick={onSubmit}
                                disabled={!bookId || !part}
                                title='Начать'
                            />
                        </div>
                    </div>
                </div>
            </Content>
        </Screen>
    )
}
