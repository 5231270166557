import type {prepareStatistics} from 'services/statistics'

type Props = {
    statistics: ReturnType<typeof prepareStatistics>
}

export default function CommonStatistics({statistics}: Props) {
    if (!statistics)
        return

    return <>
        <div className='col col_g-10'>
            <div className='row row_between row_g-10'>
                <span>&quot;Читаем с подсказкой&quot;</span>
            </div>
            <div className='row row_between row_g-10'>
                <span>&emsp;секунд:</span>
                <span className='text text_bolder'>{statistics.readingHighlightTime}</span>
            </div>
            <div className='row row_between row_g-10'>
                <span>&emsp;слов:</span>
                <span className='text text_bolder'>{statistics.readingHighlightWords}</span>
            </div>
        </div>
        <div className='col col_g-10'>
            <div className='row row_between row_g-10'>
                <span>&quot;Следим пальчиком&quot;</span>
            </div>
            <div className='row row_between row_g-10'>
                <span>&emsp;секунд:</span>
                <span className='text text_bolder'>{statistics.readingSoundTime}</span>
            </div>
            <div className='row row_between row_g-10'>
                <span>&emsp;слов:</span>
                <span className='text text_bolder'>{statistics.readingSoundWords}</span>
            </div>
        </div>
        <div className='col col_g-10'>
            <div className='row row_between row_g-10'>
                <span>&quot;Следим глазками&quot;</span>
            </div>
            <div className='row row_between row_g-10'>
                <span>&emsp;секунд:</span>
                <span className='text text_bolder'>{statistics.readingCheckTime}</span>
            </div>
            <div className='row row_between row_g-10'>
                <span>&emsp;слов:</span>
                <span className='text text_bolder'>{statistics.readingCheckWords}</span>
            </div>
            <div className='row row_between row_g-10'>
                <span>&emsp;правильных ответов:</span>
                <span className='text text_bolder'>
                    <span>{statistics.answersSuccess}</span>&nbsp;/&nbsp;
                    <span>{statistics.answersTotal}</span>
                </span>
            </div>
        </div>
        <div className='col col_g-10'>
            <div className='row row_between row_g-10'>
                <span>Правильных ответов по опроснику:</span>
                <span className='text text_bolder'>
                    <span>{statistics.assistantQuestionsSuccess}</span>&nbsp;/&nbsp;
                    <span>{statistics.assistantQuestionsTotal}</span>
                </span>
            </div>
            <div className='row row_between row_g-10'>
                <span>Пропущенных ответов опроснику:</span>
                <span className='text text_bolder'>{statistics.assistantQuestionsSkipped}</span>
            </div>
        </div>
    </>
}
