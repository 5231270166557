import {useContext, useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import Button from 'components/common/Button'
import Dialog from 'components/common/Dialog'
import {StoreContext} from 'components/common/StoreContextProvider'
import {DownloadIcon} from 'components/icons/DownloadIcon'
import {LIBRARY_PATH, type LibraryItem} from 'services/library'
import {useOnline} from 'services/online'
import {mergeSearchString} from 'services/search'

export type LoadingStatus = {
    json: number
    mp3: number
    question: number
    questions: number
    error: string
    state: string
    ready: boolean
}

type Props = {
    libraryItem: LibraryItem
}

export default function LibraryItem({libraryItem}: Props) {
    const [ready, setReady] = useState(true)
    const [loadedBookId, setLoadedBookId] = useState('')
    const [skip, setSkip] = useState(false)
    const navigate = useNavigate()

    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>({
        json: 0,
        mp3: 0,
        question: 0,
        questions: 0,
        error: '',
        state: '',
        ready: false,
    })
    const isOnline = useOnline()
    const {library, bookKeys, updateBook} = useContext(StoreContext)

    const [status, setStatus] = useState<(boolean | undefined)>()
    const skipRef = useRef<boolean>(false)

    useEffect(() => {
        const existParts = bookKeys.filter(({id}) => id == libraryItem.id)
        let status = existParts.length ? true : undefined

        if (status)
            for (let p = 0; p < libraryItem.parts_amount; p++)
                if (!existParts.some(part => part.part == `${p + 1}` && libraryItem.version == part.version))
                    status = false

        setStatus(status)
    }, [library, bookKeys, libraryItem])

    const actionRender = () => {
        const downloadButton = status
            ? null
            : <Button
                className='button button_filled button_wide'
                disabled={!isOnline || !ready}
                onClick={() => {
                    skipRef.current = false
                    setSkip(false)
                    setReady(false)
                    setLoadedBookId(libraryItem.id)
                    document.body.classList.add('no-scroll')
                    setLoadingStatus({json: 0, mp3: 0, question: 0, questions: 0, error: '', state: '', ready: false})
                    updateBook({
                        id: libraryItem.id,
                        partsAmount: libraryItem.parts_amount,
                        version: libraryItem.version,
                        questionsAmount: libraryItem.questions_amount,
                        action: 'load',
                        callback: state => setLoadingStatus(loadingStatus => ({...loadingStatus, ...state})),
                        skipRef,
                    })
                        .finally(() => {
                            setLoadingStatus(loadingStatus => ({...loadingStatus, ready: true}))
                            skipRef.current = false
                            setSkip(false)
                        })
                }
                }
                title={status == undefined
                    ? 'Загрузить'
                    : 'Обновить'
                }
                icon={status == undefined ? <DownloadIcon/> : undefined}
            />

        const deleteButton = status == undefined
            ? null
            : <Button
                className='button button_outline button_assertive button_wide'
                disabled={!ready}
                onClick={() => {
                    setReady(false)
                    updateBook({
                        id: libraryItem.id,
                        action: 'delete',
                    })
                        .finally(() => setReady(true))
                }
                }
                title='Удалить'
            />

        return <div className='row row_nowrap row_w-100 row_g-20'>
            <div className='row row_wide row_center'>
                {downloadButton}
            </div>
            <div className='row row_wide row_center'>
                {deleteButton}
            </div>
        </div>
    }

    const handleClose = () => {
        setReady(true)
        document.body.classList.remove('no-scroll')
        setLoadedBookId('')
        setLoadingStatus({json: 0, mp3: 0, question: 0, questions: 0, error: '', state: '', ready: false})
    }

    return <>
        <div className='library-item col col_w-100 col_g-30'>
            <img className='library-item__image' src={`${LIBRARY_PATH}/${libraryItem.id}/cover.png`}/>

            <div className='col col_g-40'>
                <div className='col col_g-20'>
                    <div className='library-item__title'>
                        {libraryItem.title}
                    </div>
                    <div className='library-item__description'>
                        {libraryItem.short_info}
                    </div>
                </div>
                <div className='col col_g-15 library-item__meta'>
                    <div className='row row_w-100 row_between row_g-10'>
                        <div>Количество частей:</div>
                        <div>{libraryItem.parts_amount}</div>
                    </div>
                    <div className='row row_w-100 row_between row_g-10'>
                        <div>Темп, слов в мин.:</div>
                        <div>{libraryItem.speech_tempo}</div>
                    </div>
                    <div className='row row_w-100 row_between row_g-10'>
                        <div>Продолжительность:</div>
                        <div className='text text_overflow'>{formatMillisecondsToTime(libraryItem.duration_ms)}</div>
                    </div>
                    <div className='row row_w-100 row_between row_g-10'>
                        <div>Исполнитель:</div>
                        <div className='text text_overflow'>{libraryItem.reader}</div>
                    </div>
                </div>
                {actionRender()}
            </div>
        </div>
        {!!loadedBookId &&
            <Dialog overlayClassName='overlay overlay_m' className='dialog dialog_library'>
                <div className='row row_center row_w-100 row_items-start-m'>
                    <div className='form form_main col col_g-40'>
                        <div className='table table-download'>
                            <div className='row row_between'>
                                <div>Статус</div>
                                <div>{loadingStatus.error || loadingStatus.state}</div>
                            </div>
                            <div className='row row_between'>
                                <div>Загружено фрагментов аудио</div>
                                <div>{loadingStatus.mp3} / {libraryItem.parts_amount}</div>
                            </div>
                            <div className='row row_between'>
                                <div>Загружено фрагментов текста</div>
                                <div>{loadingStatus.json} / {libraryItem.parts_amount}</div>
                            </div>
                            <div className='row row_between'>
                                <div>Загружен список вопросов</div>
                                <div>{loadingStatus.questions} / {libraryItem.questions_amount ? 1 : 0}</div>
                            </div>
                            <div className='row row_between'>
                                <div>Загружено вопросов</div>
                                <div>{loadingStatus.question} / {libraryItem.questions_amount}</div>
                            </div>
                        </div>
                        {(loadingStatus.error || loadingStatus.ready) && <>
                            <Button
                                className='button button_filled button_fixed button_px-0-m'
                                onClick={() => {
                                    handleClose()
                                }}
                                title='Закрыть'
                            />
                            {!loadingStatus.error &&
                                <Button
                                    className='button button_outline button_fixed button_px-0-m'
                                    onClick={() => {
                                        handleClose()
                                        navigate(`/session/settings/${mergeSearchString({
                                            initBookId: loadedBookId,
                                        })}`)
                                    }}
                                    title='Перейти к обучению'
                                />
                            }
                        </>}
                        {!loadingStatus.error && !loadingStatus.ready &&
                            <Button
                                className='button button_filled button_fixed'
                                onClick={() => {
                                    setSkip(true)
                                    skipRef.current = true
                                }}
                                disabled={skip}
                                title={skip ? 'Отменяется' : 'Отменить'}
                            />
                        }
                    </div>
                </div>
            </Dialog>
        }
    </>
}

export const DURATION_RATIOS = {
    ms: 1,
    s: 1000,
    min: 60 * 1000,
    h: 60 * 60 * 1000,
}

export const formatMillisecondsToTime = (milliseconds: number) => [
    durationFormatHelper(milliseconds / DURATION_RATIOS.h),
    durationFormatHelper(milliseconds / DURATION_RATIOS.min % 60),
    durationFormatHelper(milliseconds / DURATION_RATIOS.s % 60),
].join(':')

export const durationFormatHelper = (x: number) =>
    Math.floor(x).toLocaleString(undefined, {minimumIntegerDigits: 2})
