import {useContext, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import Button from 'components/common/Button'
import Content from 'components/common/Content'
import Input from 'components/common/Input'
import Screen from 'components/common/Screen'
import Select from 'components/common/Select'
import {StoreContext} from 'components/common/StoreContextProvider'
import Title from 'components/common/Title'
import {useOnline} from 'services/online'

import type {Option} from 'components/common/Select'
import type {Profile} from 'services/profile'

const newProfile: Profile = {
    id: '',
    name: '',
    age: 7,
    fontSize: 0,
}

const fontSizeOptions: Option[] = [
    {name: 'Очень мелкий', value: '-2'},
    {name: 'Мелкий', value: '-1'},
    {name: 'Средний', value: '0'},
    {name: 'Крупный', value: '1'},
    {name: 'Очень крупный', value: '2'},
]

export const FONT_SIZE_MAP: Record<string, string> = {
    '-2': '12pt',
    '-1': '14pt',
    0: '16pt',
    1: '18pt',
    2: '20pt',
}

export default function ProfileItem() {
    const isOnline = useOnline()
    const {profiles, updateProfile} = useContext(StoreContext)
    const {profileId} = useParams()
    const [profile, setProfile] = useState<Profile>(profiles.find(profile => profile.id == profileId) || newProfile)
    const navigate = useNavigate()

    const save = () => {
        updateProfile({profile, action: profile.id ? 'update' : 'add'})
            .then(() => navigate('/profile'))
    }

    const deleteProfile = () => {
        updateProfile({profile, action: 'delete'})
            .then(() => navigate('/profile'))
    }

    const onFontSizeChange = (option: Option) => setProfile(profile => ({...profile, fontSize: +option.value}))

    const option = fontSizeOptions.find(option => +option.value == profile.fontSize)

    return (
        <Screen version='6'>
            <Content>
                <Title title='Настройки профиля'/>
                <div className='form col col_g-40 col_g-30-m col_w-100 col_center'>
                    <div className='col col_g-30 col_g-20-m col_w-100'>
                        <Input
                            label='Имя профиля'
                            id='field_1'
                            value={profile.name}
                            placeholder='Ваня'
                            type='text'
                            onChange={value => setProfile(profile => ({...profile, name: value}))}
                            wide
                        />
                        <Input
                            label='Возраст'
                            id='field_2'
                            value={profile.age ?? ''}
                            placeholder='5'
                            type='number'
                            onChange={value => {
                                setProfile(profile => ({...profile, age: value ? +value : undefined}))
                            }}
                            wide
                        />
                        <Select
                            options={fontSizeOptions}
                            placeholder='Выберите размер текста'
                            label='Размер текста'
                            value={option}
                            onChange={onFontSizeChange}
                            wide
                            optionTemplate={option => <Button
                                className='control__option'
                                onClick={() => onFontSizeChange(option)}
                                title={option.name}
                                key={option.value}
                                style={{fontSize: FONT_SIZE_MAP[option.value]}}
                            />}
                        />
                    </div>
                    <div className='col col_g-30 col_g-20-m col_w-100'>
                        <div className='row row_g-20 row_w-100 row_nowrap'>
                            <div className='row row_wide row_center'>
                                <Button
                                    className='button button_filled button_wide'
                                    onClick={save}
                                    title='Сохранить'
                                    disabled={!profile.name || profile.age == undefined}
                                />
                            </div>
                            <div className='row row_wide row_center'>
                                <Button
                                    className='button button_outline button_wide'
                                    onClick={() => navigate('/profile')}
                                    title='Назад'
                                />
                            </div>
                        </div>
                        <Button
                            className='button button_w-100 button_filled button_assertive'
                            onClick={() => deleteProfile()}
                            title='Удалить ✖'
                            disabled={!profile.id || !isOnline}
                        />
                    </div>
                </div>
            </Content>
        </Screen>
    )
}
