import {useMemo, useRef} from 'react'
import {useLocation} from 'react-router-dom'

type ParamDescription = string | never[]

type ParamDescriptions = Record<string, ParamDescription>

type ParamResult<T extends ParamDescription> = T extends never[]
    ? string[]
    : string

export type ParamResults<T extends ParamDescriptions> = {
    [Key in keyof T]: ParamResult<T[Key]>
}

export function getSearchString(searchParamMap = {}) {
    return `?${populateSearchParams(searchParamMap)}`
}

export function mergeSearchString(searchParamMap = {}) {
    const searchParams = new URLSearchParams(location.search)

    for (const key of Object.keys(searchParamMap))
        searchParams.delete(key)

    return `?${populateSearchParams(searchParamMap, searchParams)}`
}

function populateSearchParams(searchParamMap: Record<string, unknown>, searchParams = new URLSearchParams) {
    return Object
        .entries(searchParamMap)
        .reduce(
            (searchParams, [name, value]) => {
                if (value !== undefined)
                    for (const singleValue of [value].flat())
                        searchParams.append(name, `${singleValue}`)

                return searchParams
            },
            searchParams,
        )
}

export function useSearchParams<T extends ParamDescriptions>(paramDescriptions: T) {
    const location = useLocation()
    const paramDescriptionsRef = useRef(paramDescriptions)

    return useMemo(
        () => getTypedSearchParams(location.search, paramDescriptionsRef.current),
        [location.search],
    )
}

export function getTypedSearchParams<T extends ParamDescriptions>(
    searchString: string,
    paramDescriptions: T,
) {
    const searchParams = new URLSearchParams(searchString)

    const typedParams = Object.fromEntries(
        Object.entries(paramDescriptions)
            .map(([name, defaultValue]) => {
                const value = Array.isArray(defaultValue)
                    ? searchParams.getAll(name)
                    : searchParams.get(name) || defaultValue

                return [name, value]
            }),
    ) as ParamResults<T>

    return {
        ...getSearchParams(searchString),
        ...typedParams,
    }
}

export function getSearchParams(searchString: string) {
    return [...new URLSearchParams(searchString).entries()]
        .reduce<Record<string, string | string[]>>(
            (paramMap, [key, value]) => {
                let existingParam = paramMap[key]

                if (existingParam) {
                    if (!Array.isArray(existingParam))
                        existingParam = paramMap[key] = [existingParam]

                    existingParam.push(value)
                } else
                    paramMap[key] = value

                return paramMap
            },
            {},
        )
}
