type Props = {
    title: string
    icon?: React.ReactNode
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export default function Button({title, icon, onClick, ...props}: Props) {
    return (
        <button
            className='button'
            type='button'
            onClick={onClick}
            {...props}
        >
            <div className='row row_nowrap row_g-15 row_center'>
                <span className='text text_overflow'>{title}</span>
                {icon && <span>{icon}</span>}
            </div>
        </button>
    )
}
