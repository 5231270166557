import {useContext, useState} from 'react'
import {useNavigate} from 'react-router'

import Button from 'components/common/Button'
import Content from 'components/common/Content'
import Input from 'components/common/Input'
import Screen from 'components/common/Screen'
import {StoreContext} from 'components/common/StoreContextProvider'
import Title from 'components/common/Title'
import {login} from 'services/login'

export default function Login() {
    const {updateUser} = useContext(StoreContext)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()

    const handleLogin = () => {
        login({username: email, password})
            .then(() => updateUser())
            // .catch(() => alert('Неверный логин или пароль'))
    }

    return (
        <Screen version='1' copyright>
            <Content>
                <Title title='Вход'/>
                <div className='form col col_g-40 col_g-30-m col_w-100 col_center'>
                    <div className='col col_g-30 col_g-20-m col_w-100'>
                        <Input
                            label='Почта'
                            id='field_1'
                            value={email}
                            placeholder='E-mail'
                            type='email'
                            onChange={value => setEmail(value)}
                            wide
                        />
                        <Input
                            label='Пароль'
                            id='field_2'
                            value={password}
                            placeholder='Пароль'
                            type='password'
                            onChange={value => setPassword(value)}
                            wide
                        />
                    </div>
                    <Button
                        className='button button_filled button_w-50 button_w-100-m'
                        onClick={() => handleLogin()}
                        title='Войти'
                        disabled={!email || !password}
                    />
                    <Button
                        className='button button_link'
                        onClick={() => navigate('/register')}
                        title='Зарегистрироваться'
                    />
                </div>
            </Content>
        </Screen>
    )
}
