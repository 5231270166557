import clsx from 'clsx'
import {useState} from 'react'
import {NavLink} from 'react-router-dom'

import {CloseIcon} from 'components/icons/CloseIcon'
import {MenuIcon} from 'components/icons/MenuIcon'

const links = [{
    name: 'Главная',
    path: '/main',
}, {
    name: 'Библиотека',
    path: '/library',
}, {
    name: 'Обучение',
    path: '/session/settings',
}, {
    name: 'Профили',
    path: '/profile',
}, {
    name: 'Статистика',
    path: '/statistics',
}]

export default function Navigation() {
    const [open, setOpen] = useState(false)

    return <>
        <div className={clsx('navigation', open && 'navigation_active')}>
            <button
                className='navigation-activator-button navigation-activator-button_close'
                onClick={() => setOpen(!open)}
            >
                <CloseIcon/>
            </button>
            {links.map(link =>
                <NavLink
                    key={link.name}
                    className={({isActive}) => clsx('navigation__item', isActive && 'navigation__item_active')}
                    to={link.path}
                    title={link.name}
                >
                    {link.name}
                </NavLink>
            )}
        </div>
        {!open && <div className='navigation-activator'>
            <button
                className='navigation-activator-button'
                onClick={() => setOpen(!open)}
            >
                <MenuIcon/>
            </button>
        </div>
        }
    </>
}

