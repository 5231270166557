import Button from 'components/common/Button'

type Props = {
    time: number
    onContinue?: (time: number) => void
    onReset?: () => void
}

export default function BookContinueDialog({time, onContinue, onReset}: Props) {
    if (!time)
        return null

    return (
        <div className='overlay'>
            <menu className='dialog'>
                <div className='col col_center col_w-100 col_g-40'>
                    <div className='row row_w-100 row_g-20 row_wrap row_center'>
                        <Button
                            className='button button_outline button_fixed button_w-80-mx'
                            title='С начала'
                            onClick={onReset}
                        />
                        <Button
                            className='button button_filled button_fixed button_w-80-mx'
                            title='Продолжить'
                            onClick={() => onContinue?.(time)}
                        />
                    </div>
                </div>
            </menu>
        </div>
    )
}
