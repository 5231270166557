import Button from 'components/common/Button'

type Props = {
    onSync?: () => Promise<unknown>
    setSync: (sync: (() => Promise<unknown>) | undefined) => void
}

export default function SyncDialog({onSync, setSync}: Props) {
    if (!onSync)
        return null

    return (
        <div className='overlay'>
            <menu className='dialog'>
                <div className='col col_center col_w-100 col_g-40'>
                    <div className='row row_px-20'>
                        <h2>Данные на устройстве не совпадают с данными на сервере. Использовать данные с устройства?</h2>
                    </div>
                    <div className='row row_w-100 row_g-20 row_wrap row_center'>
                        <Button
                            className='button button_outline button_fixed button_w-80-mx'
                            title='Да'
                            onClick={() => onSync().then(() => setSync(undefined))}
                        />
                        <Button
                            className='button button_filled button_fixed button_w-80-mx'
                            title='Нет'
                            onClick={() => {
                                setSync(undefined)
                                location.reload()
                            }}
                        />
                    </div>
                </div>
            </menu>
        </div>
    )
}
