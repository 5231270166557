
import {useContext, useEffect, useMemo, useState} from 'react'
import {useParams} from 'react-router-dom'

import Book from 'components/book/Book'
import {StoreContext} from 'components/common/StoreContextProvider'
import {useSearchParams} from 'services/search'

import type {BookDB} from 'services/database'

export type SessionQueryParams = {
    part: string
    version: string
}

export const defaultSessionQueryParams = {
    part: '1',
    version: '',
}

export default function Session() {
    const settings = useSearchParams(defaultSessionQueryParams) as SessionQueryParams
    const {part, version} = settings

    const {library, profile, getBook, deleteTempStatistics} = useContext(StoreContext)
    const {bookId} = useParams()
    const [book, setBook] = useState<BookDB | undefined>()

    const description = useMemo(
        () => bookId ? library.find(item => item.id == bookId) : undefined,
        [bookId, library]
    )

    useEffect(() => {
        if (bookId && part && version)
            getBook({id: bookId, part, version})
                .then(setBook)
                .then(() => deleteTempStatistics())
    }, [bookId, part, version, getBook, deleteTempStatistics])

    if (!book || !bookId || !part || !profile)
        return <div className='pending-fade-in'>
            Отсутствует одна из сущностей:
            Книга: {JSON.stringify(book)}
            ID книги: {bookId}
            Часть книги: {part}
            Профиль: {JSON.stringify(profile)}
        </div>

    return (
        <div className='session screen'>
            <Book
                settings={settings}
                book={book}
                bookId={bookId}
                profile={profile}
                description={description}
            />
        </div>
    )
}
