export function OpenArrowIcon() {
    return <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M1.44434 11.0236L10.7536 1.71436M10.7536 1.71436H1.44434M10.7536 1.71436V11.0236'
            stroke='currentColor'
            strokeWidth='2.06872'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
}
