import type {LibraryItem} from 'services/library'

type Props = {
    description: LibraryItem | undefined
    part: number
}

export default function BookTitle({description, part}: Props) {
    if (!description)
        return null
    const _part = part - 1
    const partName = description.part_names[_part]
    const title = partName
        ? `${description.short_title} — ${description.part_names[_part]}`
        : description.short_title

    return <div title={title} className='book-title text text_overflow'>
        {title}
    </div>
}
