/* eslint-disable max-len */

export function BlueCircleOutline() {
    return <svg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='
                M15.7783 31.317
                C12.8793 30.7036 10.0075 28.9211 7.87526 25.0963
                M15.7783 31.317
                C18.8244 31.9616 22.289 31.3826 25.3699 29.7231
                M25.3699 29.7231
                C28.4534 28.0622 30.7788 25.5223 31.8428 22.7162
                M31.8428 22.7162
                C32.8488 20.0635 32.8893 16.7853 30.7683 12.977
                M30.7672 12.975
                C28.634 9.1506 25.7616 7.36805 22.8621 6.75451
                C19.8154 6.10981 16.3506 6.68886 13.2698 8.34823
                C10.1864 10.009 7.86151 12.5488 6.7979 15.3547
                C5.79229 18.0075 5.75216 21.2863 7.87493 25.0963
            '
            stroke='#20A2EC'
            strokeWidth='11.3044'
        />
    </svg>
}
