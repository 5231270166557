import {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import Button from 'components/common/Button'
import {CloseIcon} from 'components/icons/CloseIcon'
import {mergeSearchString} from 'services/search'

type Props = {
    disabled: boolean
    playing: boolean
    onStart?: () => void
    onPause?: () => void
}

export default function BookFooter({playing, disabled, onStart, onPause}: Props) {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const handleOpen = () => {
        onPause?.()
        setOpen(true)
    }

    const handleClose = () => {
        onStart?.()
        setOpen(false)
    }

    return (
        <div className='book-footer row row_w-100 row_center'>
            <div className='form row row_w-100 row_g-20'>
                <div className='row row_wide row_center'>
                    <Button
                        className='button button_outline button_wide'
                        onClick={handleOpen}
                        disabled={disabled}
                        title='Меню'
                    />
                </div>
                <div className='row row_wide row_center'>
                    <Button
                        className='button button_filled button_wide'
                        onClick={() => playing ? onPause?.() : onStart?.()}
                        disabled={disabled}
                        title={playing ? 'Пауза' : 'Старт'}
                    />
                </div>
            </div>
            {open &&
                <div className='overlay'>
                    <div className='col col_center col_g-30 menu'>
                        <Button
                            className='button button_outline button_w-100'
                            onClick={() => navigate(`settings${mergeSearchString()}`)}
                            disabled={disabled}
                            title='Настройки'
                        />
                        {/* <Button
                            className='button button_outline button_w-100'
                            onClick={() => navigate(`statistics${mergeSearchString()}`)}
                            disabled={disabled}
                            title='Статистика'
                        /> */}
                        <Button
                            className='button button_outline button_w-100'
                            onClick={() => navigate('/main')}
                            title='Выход'
                        />

                    </div>
                    <button
                        onClick={handleClose}
                        className='overlay__close'
                    >
                        <CloseIcon/>
                    </button>
                </div>
            }
        </div>
    )
}
