import {useEffect, useState} from 'react'

class Storage {
    private readonly storage: globalThis.Storage

    constructor(nativeStorage: globalThis.Storage) {
        this.storage = nativeStorage
    }

    getItem(key: string) {
        try {
            return this.storage.getItem(key) ?? undefined
        } catch {
            return undefined
        }
    }

    setItem(key: string, value: string) {
        try {
            return this.storage.setItem(key, value)
        } catch {
            return undefined
        }
    }

    removeItem(key: string) {
        try {
            return this.storage.removeItem(key)
        } catch {
            return undefined
        }
    }

    get length() {
        try {
            return this.storage.length
        } catch {
            return 0
        }
    }
}

export const localStorageService = new Storage(localStorage)
export const sessionStorageService = new Storage(sessionStorage)

export function useSimpleStorage<T>(key: string, initialValue: T, type: 'local' | 'session' = 'local') {
    const storage = type === 'session'
        ? sessionStorageService
        : localStorageService
    const [state, setState] = useState(() => {
        const serialized = storage.getItem(key)
        return serialized && String(serialized) !== 'undefined'
            ? JSON.parse(serialized) as T
            : initialValue
    })

    useEffect(
        () => {
            if (state)
                storage.setItem(key, JSON.stringify(state))
            else
                storage.removeItem(key)
        },
        [key, state, storage]
    )

    return [state, setState] as const
}
