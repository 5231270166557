type Props = {
    children?: React.ReactNode
    className?: string
    overlayClassName?: string
}

export default function Dialog({children, overlayClassName, className}: Props) {
    return <div className={overlayClassName || 'overlay'}>
        <div className={className || 'dialog'}>
            {children}
        </div>
    </div>
}

