import {useEffect, useState} from 'react'

export function useOnline() {
    const [isOnline, setIsOnline] = useState(window.navigator.onLine)

    useEffect(
        () => {
            window.addEventListener('offline', () => setIsOnline(false))
            return () => window.removeEventListener('offline', () => setIsOnline(false))
        },
        []
    )

    useEffect(
        () => {
            window.addEventListener('online', () => setIsOnline(true))
            return () => window.removeEventListener('online', () => setIsOnline(true))
        },
        []
    )

    return isOnline
}

export const shouldSyncKey = 'shouldSync'

export function checkShouldSync<T>(args: T) {
    if (!window.navigator.onLine)
        localStorage.setItem(shouldSyncKey, '1')

    return args
}
