import {useCallback, useRef, useState} from 'react'

import Button from './Button'

import type {QuestionStatistics} from './Questions'

type Props = {
    name: string
    onClose: (props?: {skip?: boolean, questionStatistics?: QuestionStatistics}) => void
}

// TODO: учесть возможность отсуствия user action до начала проигрывания
export default function MediaContent({name, onClose}: Props) {
    const [paused, setPaused] = useState(false)
    const [end, setEnd] = useState(false)

    const videoRef = useRef<HTMLVideoElement | null>(null)

    const setRef = useCallback((videoElement: HTMLVideoElement | null) => {
        const closeHandler = () => setEnd(true)

        if (videoRef.current)
            videoRef.current.removeEventListener('ended', closeHandler)

        if (videoElement)
            videoElement.addEventListener('ended', closeHandler)

        videoRef.current = videoElement
    }, [])

    const toggle = () => {
        setPaused(!paused)
        if (!videoRef.current)
            return

        if (videoRef.current.paused)
            videoRef.current.play()
        else
            videoRef.current.pause()
    }

    const stop = () => {
        if (videoRef.current && !videoRef.current.paused)
            videoRef.current.pause()

        onClose()
    }

    const repeat = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = 0
            videoRef.current.play()
            setEnd(false)
        }
    }

    return (
        <div className='overlay'>
            <video
                ref={setRef}
                src={name}
                className='media-content-player'
                onClick={() => {
                    if (!paused)
                        toggle()
                }}
                autoPlay
                playsInline
            />
            {(paused || end) && <div className='media-content-dialog form form_main'>
                <div className='media-content-controls row row_nowrap row_g-20'>
                    <div className='row row_wide row_center'>
                        <Button
                            className='button button_outline button_wide'
                            onClick={end ? repeat : stop}
                            title={end ? 'Повторить' : 'Пропустить'}
                        />
                    </div>
                    <div className='row row_wide row_center'>
                        <Button
                            className='button button_filled button_wide'
                            onClick={end ? stop : toggle}
                            title='Продолжить'
                        />
                    </div>
                </div>
            </div>
            }
        </div>
    )
}
