import {getRandomInt} from 'utils/getRandomInt'
import {splitIntoChunks} from 'utils/splitIntoChunks'
import throttle from 'utils/throttle'

import type {ModeOption} from './devStore'
import type {QuestionStatistics} from 'components/common/Questions'
import type {Action, CheckAction, MediaAction, TestAction} from 'components/session/action'

const devOffsetWithPlayer = 125 //px
// const additionalPageOffset = 130 //px
const additionalPageOffset = devOffsetWithPlayer
const timeOffsetForPageBack = 5000 //ms
const maxPages = 100
const checksRandomPercent = 0.2
const checksInterval = 60000 //ms
const maxWordsInParagraph = 30 //ms
const maxRandomChecks = 100 //ms
const checkTimeOffsetAfterContinue = 5000

export type Book = {
    actions: (TestAction | CheckAction | MediaAction)[]
    text: Phrase[][]
    general?: {
        align?: 'center'
    }
}

export type PhraseTag = 'H1' | 'H2' | 'H3' | 'H4' | 'H5'

export type Phrase = {
    word: string
    begin: number
    end: number
    tag?: PhraseTag
}

export type PageData = {
    words?: NodeListOf<HTMLSpanElement>
    pageBegin: number
    pageEnd: number
}

export const defaultPageData = {
    words: undefined,
    pageBegin: 0,
    pageEnd: 0,
}

export const pulseCountdown = (span?: HTMLSpanElement, iterationCount = 3) => new Promise(resolve => {
    let iteration = 1
    span?.classList.add('book__word_pulse')
    const interval = setInterval(
        () => {
            if (iteration < iterationCount)
                iteration++
            else {
                clearInterval(interval)
                resolve(undefined)
                span?.classList.remove('book__word_pulse')
                iteration = 1
            }
        },
        1000
    )
})

const checkVisible = (parent: HTMLDivElement, child: HTMLDivElement) =>
    child.offsetTop + child.offsetHeight - (parent.offsetTop - additionalPageOffset) < parent.offsetHeight

export function getSpace(currentWord: string, nextWord: string, reactMode = true) {
    const allowedSymbols = ['—', '-']

    if (!nextWord)
        return reactMode ? null : ''

    if (
        allowedSymbols.includes(nextWord) ||
        nextWord.toLowerCase() !== nextWord.toUpperCase() ||
        currentWord.toLowerCase() === currentWord.toUpperCase() &&
        nextWord.toLowerCase() === nextWord.toUpperCase()
    )
        return ' '

    return reactMode ? null : ''
}

export const getPageData = (page: HTMLDivElement) => {
    const words = page.querySelectorAll('span')

    return words.length
        ? {
            words,
            pageBegin: +(words[0].dataset.begin || 0),
            pageEnd: +(words[words.length - 1].dataset.end || 0),
        }
        : defaultPageData
}

export const checkAction = (
    time: number,
    actions: Action[],
    onAction: (action: Action) => void
) => {
    for (const action of actions) {
        const {begin, fired} = action

        if (time > begin && !fired) {
            onAction(action)
            action.fired = true
            break
        }
    }
}

export const skipActionsTillTime = (actions: Action[], time: number) => actions.forEach(action => {
    if (action.begin <= time)
        action.fired = true
})

export const getTouchedTime = (callback: (time: number | undefined) => void) => throttle(
    (x: number, y: number) => {
        const {dataset} = (document.elementFromPoint(x, y) as HTMLElement | undefined) || {}

        if (!dataset)
            return

        const {touchBegin} = dataset
        callback(touchBegin ? +touchBegin : undefined)
    },
    10
)

export const proceedWord = (
    time: number,
    pageData: PageData,
    mode: ModeOption['id'],
    changePage: (offset: number) => void,
) => {
    if (!pageData.words?.length)
        return

    const removeHighlight = () => pageData.words?.forEach(node => node.classList.remove('book__word_active'))

    if (time < pageData.pageBegin - timeOffsetForPageBack) {
        removeHighlight()
        return changePage(-1)
    }

    if (time > pageData.pageEnd) {
        removeHighlight()
        return changePage(1)
    }

    if (mode == 'highlight')
        pageData.words.forEach(node => node.classList.toggle(
            'book__word_active',
            !!(node.dataset.begin && +node.dataset.begin < time + 0.2)
        ))
    else
        removeHighlight()
}

export const calculatePages = (data: Phrase[][], container: HTMLDivElement | null) => {
    if (!data.length || !container)
        return []

    const fakePage = document.createElement('div')
    fakePage.classList.add('book__page', 'book__page_fake')

    data.forEach(phrase => {
        const paragraphNode = document.createElement('p')
        paragraphNode.dataset.data = JSON.stringify(phrase)
        phrase.forEach((word, index) => {
            const wordNode = document.createElement('span')
            wordNode.classList.add('book__word')
            !word.tag || wordNode.classList.add(`book__word_${word.tag}`)
            wordNode.innerHTML = `${word.word}${getSpace(word.word, phrase[index + 1]?.word, false)}`
            paragraphNode.appendChild(wordNode)
        })
        fakePage.appendChild(paragraphNode)
    })

    container.appendChild(fakePage)

    const pages: {data: Phrase[], element: HTMLParagraphElement}[][] = []

    for (let pageIndex = 0; fakePage.childNodes.length > 0 && pageIndex < maxPages; pageIndex++) {
        pages.push([])
        for (const phrase of fakePage.childNodes as NodeListOf<HTMLParagraphElement>) {
            const visible = checkVisible(fakePage, phrase)

            if (visible || pages[pageIndex].length === 0)
                pages[pageIndex].push({
                    data: JSON.parse(phrase.dataset.data || '') as Phrase[],
                    element: phrase,
                })
        }

        pages[pageIndex].forEach(({element}) => fakePage.removeChild(element))
    }

    container.removeChild(fakePage)

    return pages.map(data => data.map(({data}) => data))
}

export const colors = Object.fromEntries(
    Array.from({length: 10}, (_, index) => [index, `rgb(${Math.round(255 / 10 * index)}, 93, 78, 0.6)`])
)

const getRandomCheck = (checksInterval: number) => {
    const percentage = checksInterval * checksRandomPercent
    return getRandomInt(checksInterval - percentage, checksInterval + percentage)
}

export const getRandomChecks = (text: Phrase[][], offsetTime = 0, _checksInterval = checksInterval) => {
    const duration = text.flat().at(-1)?.end || 0
    const textPhraseEnds = text.flat()
        .map(({end}) => end)
        .toReversed()
    const checks: number[] = []

    while ((checks.at(-1) || 0) < duration && checks.length < maxRandomChecks)
        checks.push((checks.at(-1) || 0) + getRandomCheck(_checksInterval))

    return checks
        .filter(check => check < duration - checkTimeOffsetAfterContinue &&
            (
                !offsetTime ||
                check > offsetTime + checkTimeOffsetAfterContinue
            )
        )
        .map(check => textPhraseEnds.find(phraseEnd => check > phraseEnd))
        .filter((check): check is number => Boolean(check))
}

const sentenceEndings = /[А-Яа-яЁё](…|\.{3,}|\.|!|\?)/g
export const prepareRawText = (text: Phrase[][]) => text
    .flatMap(phrase => phrase.length > maxWordsInParagraph
        ? splitIntoChunks(phrase, ({word}) => Boolean(word.match(sentenceEndings)))
        : [phrase]
    )

export type TrackingStatus = 'success' | 'mistake' | 'missed'

export type TempStatistics = {
    profileId: string
    bookId: string
    part: string
    version: string
    mode: string
    sessionFinished?: boolean
    sessionPausedTime?: number
    totalTime: number
    sessionDuration: number
    sessionWordsCount: number
    questionStatistics?: QuestionStatistics
    trackingData: Record<string, TrackingStatus | undefined>
    history: TempStatisticsData[]
}

export type TempStatisticsData = {
    audioTime?: number
    touchedTime?: number
    pausedTime?: number
    soundModeTouchTime?: number
    soundModeTime?: number
    actionData?: Action
    actionResult?: 'success' | 'fault'
    actionTime?: number
    mode: ModeOption['id']
    skipCountdown: boolean
    pauseOnPageChange: boolean
}

export const handleBookError = (...data: unknown[]) => {
    alert('Упс! Подробности в консоли')
    return console.error(data)
}
