import StatisticsProgress from './StatisticsProgress'
import {formatPercent, type prepareStatistics} from 'services/statistics'

type Props = {
    statistics: ReturnType<typeof prepareStatistics>
}

const levelWords = 1000

const preventNaN = (value: number) => isNaN(value)
    ? 0
    : value

export default function TotalStatistics({statistics}: Props) {
    if (!statistics)
        return

    const {
        totalWords,
        answersTotal,
        totalTrackedSuccess,
        assistantQuestionsTotal,
        readingHighlightWords,
        answersSuccess,
        assistantQuestionsSuccess,
        totalTracked,
    } = statistics

    const independence = preventNaN((totalWords - readingHighlightWords) / totalWords)
    const trackingAccuracy = (
        preventNaN(answersSuccess / answersTotal) +
        preventNaN(totalTrackedSuccess / totalTracked)
    ) / preventNaN([answersTotal, totalTrackedSuccess].filter(Boolean).length)

    const readingComprehension = preventNaN(assistantQuestionsSuccess / assistantQuestionsTotal)
    const weightedData: [number, number][] = [
        [independence, 1],
        [trackingAccuracy, 1],
        [readingComprehension, 1],
    ]

    return <div className='total-statistics col col_g-50'>
        <div className='col col_g-30'>
            <div className='row row_g-20'>
                <span className='total-statistics__title'>Уровень:</span>
                <span className='total-statistics__title total-statistics__title_red text text_bolder'>
                    {Math.floor(totalWords / levelWords)}
                </span>
            </div>
            <div className='row row_center-y row_between row_g-10 total-statistics__subtitle'>
                <span>Прогресс перехода на следующий уровень:</span>
                <span className='text text_bold'>
                    {formatPercent(totalWords / levelWords % 1)}
                </span>
            </div>
            <StatisticsProgress value={totalWords / levelWords % 1} limit={0}/>
        </div>

        <div className='col col_g-30'>
            <div className='row row_between row_g-10'>
                <span className='total-statistics__title'>Самостоятельность</span>
            </div>
            <div className='row row_center-y row_between row_g-10 total-statistics__subtitle'>
                <span>Обучение в режимах слежения с пальчиком или глазками:</span>
                <span className='text text_bold'>
                    {formatPercent(independence, true)}
                </span>
            </div>
            <StatisticsProgress value={independence}/>
        </div>

        {/* TODO: тут нужно отличать правильно слежения в разных режимах */}
        <div className='col col_g-30'>
            <div className='row row_between row_g-10'>
                <span className='total-statistics__title'>Правильность слежения</span>
            </div>
            <div className='row row_center-y row_between row_g-10 total-statistics__subtitle'>
                <span>В режимах «следим пальчиком» и «следим глазками»:</span>
                <span className='text text_bold'>
                    {formatPercent(trackingAccuracy, true)}
                </span>
            </div>
            <StatisticsProgress value={trackingAccuracy}/>
        </div>

        <div className='col col_g-30'>
            <div className='row row_between row_g-10'>
                <span className='total-statistics__title'>Понимание прочитанного</span>
            </div>
            <div className='row row_center-y row_between row_g-10 total-statistics__subtitle'>
                <span>Доля правильных ответов на вопросы:</span>
                <span className='text text_bold'>
                    {formatPercent(readingComprehension, true)}
                </span>
            </div>
            <StatisticsProgress value={readingComprehension}/>
        </div>

        <div className='col col_g-30'>
            <div className='row row_between row_g-10'>
                <span className='total-statistics__title'>Общая оценка</span>
            </div>
            <div className='row row_center-y row_between row_g-10 total-statistics__subtitle'>
                <span>С учетом самостоятельности, правильность слежения и понимания:</span>
                <span className='text text_bold'>
                    {formatPercent(calculateGrade(...weightedData), true)}
                </span>
            </div>
            <StatisticsProgress value={calculateGrade(...weightedData)}/>
        </div>
    </div>
}

const calculateGrade = (...grades: [number, number][]) => {
    const weights = grades.map(([, weight]) => weight)
    return grades.reduce((sum, [score, weight]) => sum + score * weight, 0) / weights.reduce((sum, weight) => weight + sum, 0)
}
