import {useState} from 'react'
import {useNavigate} from 'react-router'

import Button from 'components/common/Button'
import Content from 'components/common/Content'
import Input from 'components/common/Input'
import Screen from 'components/common/Screen'
import Title from 'components/common/Title'
import {register} from 'services/login'

export default function Registeration() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [repeatedPassword, setRepeatedPassword] = useState('')
    const [state, setState] = useState<boolean>()
    const [ready, setReady] = useState(true)
    const navigate = useNavigate()

    const handleRegister = () => {
        setReady(false)
        register({username: email, password, verifyPassword: repeatedPassword})
            .then(() => setState(true))
            .catch(() => setState(false))
            .finally(() => setReady(true))
        navigate('/register/success')
    }

    return (
        <Screen version='2' copyright>
            <Content>
                <Title title='Регистрация'/>
                {state == undefined &&
                <div className='form col col_g-40 col_g-30-m col_w-100 col_center'>
                    <div className='col col_g-30 col_g-20-m col_w-100'>
                        <Input
                            label='Почта'
                            id='field_1'
                            value={email}
                            placeholder='E-mail'
                            type='email'
                            onChange={value => setEmail(value)}
                            wide
                        />
                        <Input
                            label='Пароль'
                            id='field_2'
                            value={password}
                            placeholder='Пароль'
                            type='password'
                            onChange={value => setPassword(value)}
                            wide
                        />
                        <Input
                            label='Подтвердите пароль'
                            id='field_3'
                            value={repeatedPassword}
                            placeholder='Повторите пароль'
                            type='password'
                            onChange={value => setRepeatedPassword(value)}
                            wide
                        />
                    </div>
                    <Button
                        className='button button_filled button_w-50 button_w-100-m'
                        onClick={() => handleRegister()}
                        title='Зарегистрироваться'
                        disabled={!ready || !email || !password || password != repeatedPassword}
                    />
                    <Button
                        disabled={!ready}
                        className='button button_link'
                        onClick={() => navigate('/login')}
                        title='Войти'
                    />
                </div>
                }

                {state == true && <>
                    <div className='text text_primary text_center text_size-28 text_size-14-m'>
                        Пользователь успешно зарегестрирован
                    </div>
                    <div className='form col col_w-100 col_center'>
                        <Button
                            disabled={!ready}
                            className='button button_filled button_w-50 button_w-100-m'
                            onClick={() => navigate('/login')}
                            title='Войти'
                        />
                    </div>
                </>}
                {state == false && <>
                    <div className='text text_assertive text_center text_size-28 text_size-14-m'>
                        Ошибка при регистрации
                    </div>
                    <div className='form col col_g-40 col_g-30-m col_w-100 col_center'>
                        <Button
                            disabled={!ready}
                            className='button button_filled button_w-50 button_w-100-m'
                            onClick={() => setState(undefined)}
                            title='Зарегистрироваться'
                        />
                        <Button
                            disabled={!ready}
                            className='button button_link'
                            onClick={() => navigate('/login')}
                            title='Войти'
                        />
                    </div>
                </>}
            </Content>
        </Screen>
    )
}
