import {useEffect, useState} from 'react'
import ReactJson from 'react-json-view'
import {useNavigate, useParams} from 'react-router-dom'

import {getDatabaseJSON, wipeDatabase} from 'services/database'
import {logout} from 'services/login'

export function SystemRoute() {
    const {action} = useParams()
    const navigate = useNavigate()
    const [JSON, setJSON] = useState<object>()

    useEffect(
        () => {
            if (!action)
                return
            if (action === 'wipe')
                wipeDatabase()
                    .then(logout)
                    .then(() => navigate('/'))
            if (action === 'json')
                getDatabaseJSON().then(setJSON)
        },
        [action, navigate]
    )

    return JSON
        ? <div className='react-json-view'>
            <ReactJson
                sortKeys
                collapsed={1}
                src={JSON}
                displayDataTypes={false}
                displayObjectSize={false}
                indentWidth={10}
                collapseStringsAfterLength={100}
                name={false}
                theme='summerfruit:inverted'
            />
        </div>
        : null
}
