import {useNavigate} from 'react-router-dom'

import {OpenArrowIcon} from 'components/icons/OpenArrowIcon'

type Props = {
    path: string
    title: string
    modifier: string
}

export default function MainButton({path, title, modifier}: Props) {
    const navigate = useNavigate()

    return <button
        className={`main-button main-button_${modifier}`}
        type='button'
        onClick={() => navigate(path)}
    >
        <span className='main-button__title'>
            {title}
        </span>
        <span className='main-button__icon'>
            <OpenArrowIcon/>
        </span>
    </button>
}
