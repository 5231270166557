// import {DEV_LIBRARY} from './devStore'
import {http} from './http'
import {BACKEND, BACKEND_LIBRARY} from 'config'

import type {Book} from './book'
import type {QuestionsData} from './questions'

export const LIBRARY_PATH = process.env.NODE_ENV == 'development'
    ? `${window.location.origin}/library`
    // ? `${BACKEND_LIBRARY}/library`
    : `${BACKEND_LIBRARY}/library`

export type LibraryItem = {
    author: string
    id: string
    title: string
    short_title: string
    version: string
    short_info: string
    parts_amount: number
    questions_amount: number
    speech_tempo: number
    duration_ms: number
    reader: string
    part_names: string[]
}

export const getLibrary = () => http.get<LibraryItem[]>(`${BACKEND}/library`)

export const getLibraryItemMp3 = (id: string, part: string) =>
    http.get<Blob>(`${LIBRARY_PATH}/${id}/part_${part}.mp3`, {}, {}, true)

export const getLibraryItemJson = (id: string, part: string) =>
    http.get<Book>(`${LIBRARY_PATH}/${id}/part_${part}.json`)

export const getLibraryItemQuestionsJson = (id: string) =>
    http.get<QuestionsData>(`${LIBRARY_PATH}/${id}/questions.json`)

export const getLibraryItemQuestionMp3 = (id: string, question: string) =>
    http.get<Blob>(`${LIBRARY_PATH}/${id}/question_${question}.mp3`, {}, {}, true)

// Dev only
// export const getLibrary = () => Promise.resolve(DEV_LIBRARY)
