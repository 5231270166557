type Props = {
    title: string
}

export default function Title({title}: Props) {
    const words = title.split(' ').map(([letter, ...rest]) => [letter, rest])

    return (
        <h1 className='title'>
            {words.map(([letter, rest], i) =>
                <span key={i} className='title__word'>
                    <span className='title__letter'>{letter}</span>
                    {rest}{i < words.length - 1 ? <>&nbsp;</> : null}
                </span>
            )}
        </h1>
    )
}
