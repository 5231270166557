let lockRef: WakeLockSentinel | null = null

const lock = () => navigator.wakeLock.request('screen')
    .then(lock => {
        lockRef = lock
        console.log('wake lock locked')
    })

const unlock = () => new Promise(resolve => {
    if (lockRef && !lockRef.released) {
        lockRef.onrelease = resolve
        lockRef.release()
        lockRef = null
        console.log('wake lock released')
    }
})

export const toggleWakeLock = (force = lockRef?.released) => new Promise((resolve, reject) => {
    try {
        (force ? lock : unlock)()
            .then(resolve)
    } catch (error) {
        reject(error)
    }
})
