/* eslint-disable max-len */

export function DownloadIcon() {
    return <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M8.82292 13.333L3.40625 7.91634L4.92292 6.34551L7.73958 9.16217V0.333008H9.90625V9.16217L12.7229 6.34551L14.2396 7.91634L8.82292 13.333ZM2.32292 17.6663C1.72708 17.6663 1.21719 17.4544 0.79325 17.0304C0.369305 16.6065 0.156972 16.0962 0.15625 15.4997V12.2497H2.32292V15.4997H15.3229V12.2497H17.4896V15.4997C17.4896 16.0955 17.2776 16.6058 16.8537 17.0304C16.4297 17.4551 15.9195 17.6671 15.3229 17.6663H2.32292Z'
            fill='currentColor'
        />
    </svg>
}
