import {useCallback, useContext, useEffect, useRef, useState} from 'react'

import Button from './Button'
import {StoreContext} from './StoreContextProvider'

import type {QuestionsAction} from 'components/session/action'
import type {BookDB, QuestionDB} from 'services/database'
import type {Question} from 'services/questions'

export type QuestionStatistics = {
    book_id: string
    question_id: string
    part: string
    version: string
    total: number
    correct: number
    wrong: number
    skip: number
    details: {
        id: string
        state: -1 | 0 | 1 // -1: wrong | 0: skip | 1: correct
    }[]
}

type Props = {
    book: BookDB
    action: QuestionsAction
    onClose: (props?: {skip?: boolean, questionStatistics?: QuestionStatistics}) => void
}

const questionAudioPlayer = new Audio()

const delay = (ms: number, proceed: boolean) => new Promise(resolve => setTimeout(() => resolve(proceed), ms))

const play = (src: string, onload?: () => void): Promise<boolean> => new Promise((resolve, reject) => {
    const play = () => {
        onload?.()
        questionAudioPlayer.play()
    }

    questionAudioPlayer.addEventListener('canplaythrough', play, {once: true})
    questionAudioPlayer.addEventListener('ended', () => resolve(true), {once: true})
    questionAudioPlayer.addEventListener('error', reject, {once: true})
    questionAudioPlayer.addEventListener('pause', () => resolve(false), {once: true})

    questionAudioPlayer.src = src
})

const isCorrectQuestions = ['QuestionsYesOrNo', 'QuestionsTrueOrFalse']
const getIsCorrectMp3Name = () => isCorrectQuestions[Math.floor(Math.random() * isCorrectQuestions.length)]

const correctAnswers = ['QuestionsCorrect1', 'QuestionsCorrect2', 'QuestionsCorrect3']
const getCorrectAnswerMp3Name = () => correctAnswers[Math.floor(Math.random() * correctAnswers.length)]

const wrongAnswersNo = ['QuestionsWrong1', 'QuestionsWrong2', 'QuestionsWrongNo']
const getWrongAnswersNoMp3Name = () => wrongAnswersNo[Math.floor(Math.random() * wrongAnswersNo.length)]

const wrongAnswersYes = ['QuestionsWrong1', 'QuestionsWrong2', 'QuestionsWrongYes']
const getWrongAnswersYesMp3Name = () => wrongAnswersYes[Math.floor(Math.random() * wrongAnswersYes.length)]

const getAssistantMp3Path = (name: string) => `/media/assistant/${name}.mp3`

export default function Questions({book, action, onClose}: Props) {
    const {getQuestion, questionDescriptions} = useContext(StoreContext)
    const [questions, setQuestions] = useState<Question[]>()
    const [question, setQuestion] = useState<QuestionDB>()
    const [currentIndex, setCurrentIndex] = useState<number>()
    const [playerReady, setPlayerReady] = useState(true)
    const statistics = useRef<QuestionStatistics>()

    const reset = useCallback(() => {
        setQuestion(undefined)
        setCurrentIndex(undefined)
        setQuestions(undefined)
    }, [])

    const close = useCallback((skip?: boolean) => {
        reset()
        onClose({skip, questionStatistics: statistics.current ? {...statistics.current} : undefined})
    }, [onClose, reset])

    useEffect(() => {
        const questions = questionDescriptions
            .find(description => description.id == book.id && description.version == book.version)?.json.data
            .find(({id}) => id == action.questions_id)?.questions

        setQuestions(questions)
        statistics.current = {
            question_id: action.questions_id,
            book_id: book.id,
            part: book.part,
            version: book.version,
            total: questions?.length || 0,
            correct: 0,
            wrong: 0,
            skip: 0,
            details: [],
        }
    }, [action.questions_id, book, questionDescriptions])

    useEffect(() => {
        if (!questions) {
            setCurrentIndex(undefined)
            return
        }

        setCurrentIndex(0)
    }, [questions])

    useEffect(() => {
        if (!questions || currentIndex == undefined)
            return

        if (currentIndex >= questions.length) {
            close()
            return
        }

        getQuestion({
            id: book.id,
            version: book.version,
            question: questions[currentIndex].id,
        })
            .then(data => setQuestion(data))
    }, [questions, currentIndex, book, getQuestion, close])

    useEffect(() => {
        if (!question)
            return

        play(URL.createObjectURL(question.mp3))
            .then(proceed => delay(150, proceed))
            .then(proceed => proceed && play(getAssistantMp3Path(getIsCorrectMp3Name())))
            .catch(reason => {
                if (reason == 'skip')
                    console.log('Аудио было прервано')
                else
                    console.log('Тут должна быть обработка ошибок', reason)
            })
    }, [question])

    if (!questions || currentIndex == undefined || currentIndex >= questions.length)
        return null

    const response = (answer: boolean) => {
        let playPromise
        setPlayerReady(false)
        if (
            answer && questions[currentIndex].answer.toLowerCase() == 'да' ||
            !answer && questions[currentIndex].answer.toLowerCase() == 'нет'
        ) {
            if (statistics.current)
                statistics.current.correct += 1

            playPromise = () => play(getAssistantMp3Path(getCorrectAnswerMp3Name()))
        } else {
            if (statistics.current)
                statistics.current.wrong += 1

            if (answer)
                playPromise = () => play(getAssistantMp3Path(getWrongAnswersYesMp3Name()))
            else
                playPromise = () => play(getAssistantMp3Path(getWrongAnswersNoMp3Name()))
        }

        playPromise()
            .then(() => {
                setCurrentIndex(currentIndex + 1)
                setPlayerReady(true)
            })
            .catch(error => console.log('Тут должна быть обработка ошибок', error))
    }

    const skip = () => {
        if (statistics.current)
            statistics.current.skip += questions.length - currentIndex

        questionAudioPlayer.pause()
        close(true)
    }

    return (
        <div
            className='questions screen'
        >
            <div className='col col_center col_g-50'>
                <div className='questions__question'>
                    {questions[currentIndex].question}
                </div>
                <div className='form col col_center col_g-40'>
                    <div className='row row_center row row_w-100 row_g-20'>

                        <div className='row row_wide row_center'>
                            <Button
                                className='button button_outline button_wide'
                                onClick={() => response(false)}
                                disabled={!playerReady}
                                title='Нет'
                            />

                        </div>
                        <div className='row row_wide row_center'>
                            <Button
                                className='button button_filled button_wide'
                                onClick={() => response(true)}
                                disabled={!playerReady}
                                title='Да'
                            />
                        </div>
                    </div>
                    <button
                        className='button button_link'
                        onClick={() => skip()}
                    >
                        Пропустить
                    </button>
                </div>
            </div>
        </div>
    )
}
