
import {openDB, type DBSchema, type IDBPDatabase} from 'idb'

import {activateProfile, updateProfile, type Profile} from './profile'
import {denormalizeStatistics, getBookStatistics, updateBookStatistics, type Statistics} from './statistics'

import type {Book, TempStatistics} from './book'
import type {LibraryItem} from './library'
import type {QuestionsData} from './questions'

type BaseStoreDatabase = {
    profiles: {
        key: string
        value: Profile
    }
    session: {
        key: 'username' | 'profileId'
        value: string
    }
    library: {
        key: string
        value: LibraryItem
    }
    books: {
        key: [string, string, string]
        value: BookDB
    }
    questionDescriptions: {
        key: [string, string]
        value: QuestionDescription
    }
    questions: {
        key: [string, string, string]
        value: QuestionDB
    }
    statistics: {
        key: [string, string, string]
        value: Statistics
    }
    tempStatistics: {
        key: 'tempStatistics'
        value: TempStatistics
    }
}

export interface StoreDatabase extends DBSchema, BaseStoreDatabase {}

// export interface StoreDatabase extends DBSchema {
//     profiles: {
//         key: string
//         value: Profile
//     }
//     session: {
//         key: 'username' | 'profileId'
//         value: string
//     }
//     library: {
//         key: string
//         value: LibraryItem
//     }
//     books: {
//         key: [string, string, string]
//         value: BookDB
//     }
//     questionDescriptions: {
//         key: [string, string]
//         value: QuestionDescription
//     }
//     questions: {
//         key: [string, string, string]
//         value: QuestionDB
//     }
//     statistics: {
//         key: [string, string, string, string]
//         value: Statistics
//     }
// }

export type QuestionDescription = {
    id: string
    version: string
    json: QuestionsData
}

export type QuestionDB = {
    id: string
    version: string
    question: string
    mp3: Blob
}

export type BookDB = {
    id: string
    version: string
    part: string
    json: Book
    mp3: Blob
}

export function clearFullDatabase(database: IDBPDatabase<StoreDatabase>) {
    const transaction = database.transaction(['session', 'profiles', 'library', 'books', 'statistics', 'tempStatistics'], 'readwrite')
    transaction.objectStore('session').clear()
    transaction.objectStore('profiles').clear()
    transaction.objectStore('library').clear()
    // TODO: архитектурно спорный момент, но пока так, в будущем нужно подумать в сторону оставления книг при логауте
    transaction.objectStore('books').clear()
    transaction.objectStore('statistics').clear()
    transaction.objectStore('tempStatistics').clear()
    return transaction.done
}

type AvaliableStores = (keyof BaseStoreDatabase)[]

export function readDatabase(database: IDBPDatabase<StoreDatabase>, stores?: AvaliableStores) {
    stores ||= ['session', 'profiles', 'library', 'books', 'statistics', 'questions', 'questionDescriptions', 'tempStatistics']

    const transaction = database.transaction(stores, 'readwrite')

    const getStore = <T, D>(store: keyof BaseStoreDatabase, transaction: () => T, defaultValue: D) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (stores?.includes(store))
            return transaction()

        return Promise.resolve(defaultValue)
    }

    return Promise.all([
        getStore('session', () => transaction.objectStore('session').get('username'), undefined),
        getStore('session', () => transaction.objectStore('session').get('profileId'), undefined),
        getStore('profiles', () => transaction.objectStore('profiles').getAll(), [] as Profile[]),
        getStore('library', () => transaction.objectStore('library').getAll(), [] as LibraryItem[]),
        getStore('books', () => transaction.objectStore('books').getAllKeys(), [] as [string, string, string][]),
        getStore('questions', () => transaction.objectStore('questions').getAllKeys(), [] as [string, string, string][]),
        getStore('questionDescriptions', () => transaction.objectStore('questionDescriptions').getAll(), [] as QuestionDescription[]),
        getStore('statistics', () => transaction.objectStore('statistics').getAll(), []),
        getStore('tempStatistics', () => transaction.objectStore('tempStatistics').get('tempStatistics'), undefined),
    ])
        .then(([username, profileId, profiles, library, bookKeys, questionsKeys, questionDescriptions, statistics, tempStatistics]) => ({
            username,
            profileId,
            profiles,
            library,
            bookKeys,
            questionsKeys,
            questionDescriptions,
            statistics,
            tempStatistics,
        }))
}

export type SynchronizationProps = {
    username: string | undefined
    profileId: string | undefined
    profiles: Profile[]
    library: LibraryItem[]
    statistics: Statistics[]
}

const syncStatisticsToBackend = async(
    database: IDBPDatabase<StoreDatabase>,
    props: SynchronizationProps
) => {
    const {statistics, library, profiles} = props

    const updateProfileStatistics = async(profileId: string) => {
        try {
            await Promise.all(statistics.map(item => updateBookStatistics(denormalizeStatistics(item))))
            const syncedStatistics = await Promise.all(library.map(item => getBookStatistics(profileId, item.id)))
            syncedStatistics
                .filter(item => item.length)
                .flat()
                .map(item => ({
                    ...item,
                    bookId: String(item.bookId),
                    profileId: String(item.profileId),
                    part: String(item.part),
                }))
                .forEach(item => database.put('statistics', item))
        } catch (error) {
            console.error(error)
        }
    }

    if (!profiles.length)
        return Promise.resolve()

    return Promise.all(profiles.map(({id}) => updateProfileStatistics(id)))
}

const syncProfilesToBackend = async(
    database: IDBPDatabase<StoreDatabase>,
    props: SynchronizationProps
) => {
    const {profiles, profileId} = props

    try {
        if (profileId)
            await activateProfile(profileId)

        await Promise.all(profiles.map(updateProfile))
        //TODO gl-i-43 сейчас настройки сессии сохраняются только в indexDB - раскоментировать после того как Прохор добавить ключи
        // updatedProfiles.forEach(item => database.put('profiles', item))
    } catch (error) {
        console.error(error)
    }
}

export async function synchronizationToBackend(props: SynchronizationProps, database: IDBPDatabase<StoreDatabase>) {
    console.log('synchronizationToBackend', props.username)
    if (!props.username)
        return Promise.resolve()

    return Promise.all([
        syncStatisticsToBackend(database, props),
        syncProfilesToBackend(database, props),
    ])
}

export function wipeDatabase() {
    return indexedDB.databases()
        .then(databases => Promise.all(databases.map(database => database.name && indexedDB.deleteDatabase(database.name))))
}

export function getDatabaseJSON() {
    return indexedDB.databases()
        .then(databases => Promise.all(databases.map(({name, version}) => openDB(name || '', version))))
        .then(databases => Promise.all(databases.map(database => readDatabase(database as IDBPDatabase<StoreDatabase>))))
}

export function synchronizationToDatabase(database: IDBPDatabase<StoreDatabase>, props: Partial<SynchronizationProps>) {
    console.log('synchronizationToDatabase', props)
    const transaction = database.transaction(['session', 'profiles', 'library', 'statistics'], 'readwrite')
    console.log('props', props)
    if ('username' in props)
        transaction.objectStore('session').put(props.username || '', 'username')
    if ('profileId' in props)
        transaction.objectStore('session').put(props.profileId || '', 'profileId')
    if ('profiles' in props)
        props.profiles?.forEach(profile => transaction.objectStore('profiles').put(profile))
    if ('library' in props)
        props.library?.forEach(libraryItem => transaction.objectStore('library').put(libraryItem))

    return transaction.done
}
