import {BlueCircleFill} from 'components/icons/BlueCircleFill'
import {BlueCircleOutline} from 'components/icons/BlueCircleOutline'
import {GreyCircleBig} from 'components/icons/GreyCircleBig'
import {GreyCircleMedium} from 'components/icons/GreyCircleMedium'
import {GreyCircleSmall} from 'components/icons/GreyCircleSmall'
import {RedCircleFill} from 'components/icons/RedCircleFill'
import {RedCircleOutlineLeft} from 'components/icons/RedCircleOutlineLeft'
import {RedCircleOutlineRight} from 'components/icons/RedCircleOutlineRight'

type Props = {
    children?: React.ReactNode
    version?: string
    copyright?: boolean
}

export default function Screen({version, children, copyright}: Props) {
    return <div className='screen'>
        {version &&
            <div className={`screen__decorations screen__decorations_${version}`}>
                <div className='screen__decoration screen__decoration_gcm'>
                    <GreyCircleMedium/>
                </div>
                <div className='screen__decoration screen__decoration_gcb'>
                    <GreyCircleBig/>
                </div>
                <div className='screen__decoration screen__decoration_gcs'>
                    <GreyCircleSmall/>
                </div>
                <div className='screen__decoration screen__decoration_rcf'>
                    <RedCircleFill/>
                </div>
                <div className='screen__decoration screen__decoration_rco-right'>
                    <RedCircleOutlineRight/>
                </div>
                <div className='screen__decoration screen__decoration_rco-left'>
                    <RedCircleOutlineLeft/>
                </div>
                <div className='screen__decoration screen__decoration_bco-right'>
                    <BlueCircleOutline/>
                </div>
                <div className='screen__decoration screen__decoration_bcf'>
                    <BlueCircleFill/>
                </div>
                <div className='screen__decoration screen__decoration_bco-left'>
                    <BlueCircleOutline/>
                </div>
            </div>
        }
        <div className='screen__content'>
            {children}
        </div>
        {copyright && <div className='copyright'>© ООО «Школьные технологии», 2023-{new Date().getFullYear()}.</div>}
    </div>
}

