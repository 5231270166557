/* eslint-disable no-console */
import {forwardRef, useImperativeHandle, useRef} from 'react'

import {isDev} from 'config'

export type BookPlayerRef = {
    play: (time?: number) => Promise<unknown>
    pause: () => Promise<unknown>
    isPaused: () => boolean
    getTime: () => number | undefined
}

type Props = {
    src: string
    onTimeUpdate: (time: number) => void
    onEnd: () => void
}

export default forwardRef<BookPlayerRef, Props>(function BookPlayer({src, onTimeUpdate, onEnd}, ref) {
    const playerRef = useRef<HTMLAudioElement>(null)

    useImperativeHandle(ref, () => ({
        play: time => new Promise(resolve => {
            if (!playerRef.current)
                return

            if (time !== undefined)
                playerRef.current.currentTime = time / 1000
            playerRef.current.onplay = resolve
            playerRef.current.play()
        }),
        pause: () => new Promise(resolve => {
            if (!playerRef.current)
                return
            playerRef.current.onpause = resolve
            playerRef.current.pause()
        }),
        getTime: () => playerRef.current?.currentTime,
        isPaused: () => Boolean(playerRef.current?.paused),
    }))

    return <audio
        className='book-player'
        ref={playerRef}
        onTimeUpdate={event => {
            onTimeUpdate(event.currentTarget.currentTime * 1000)
            !isDev || console.log(event.currentTarget.currentTime)
        }}
        onEnded={onEnd}
        src={src}
        controls={isDev}
    />
})
