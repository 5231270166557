import {useContext, useEffect, useState} from 'react'

import {http} from './http'
import {checkShouldSync} from './online'
import {StoreContext} from 'components/common/StoreContextProvider'
import {BACKEND, isDev} from 'config'

import type {TempStatistics} from './book'
import type {QuestionStatistics} from 'components/common/Questions'

export type CheckAnswer = {
    success: boolean
    time: number
}

type History = {
    readingCheckTime: number
    readingHighlightTime: number
    readingSoundTime: number
    readingCheckWords: number
    readingHighlightWords: number
    readingSoundWords: number
    checkAnswers: CheckAnswer[]
    bookLength: number
    readLength: number
    totalFollowed: number
    totalFollowedSuccess: number
    totalWords: number
    totalTime: number
    totalTracked: number
    totalTrackedSuccess: number
    questionStatistics: QuestionStatistics[]
}

export type Statistics = {
    profileId: string
    bookId: string
    part: string
    history?: History
}

export type RawStatistics = {
    profile_id: string
    book_id: string
    part_id: string
    history: string
}

export const successDelta = 1500
const defaultHistory: History = {
    checkAnswers: [],
    readingCheckTime: 0,
    readingHighlightTime: 0,
    readingSoundTime: 0,
    readingCheckWords: 0,
    readingHighlightWords: 0,
    readingSoundWords: 0,
    bookLength: 0,
    readLength: 0,
    totalFollowed: 0,
    totalFollowedSuccess: 0,
    totalWords: 0,
    totalTime: 0,
    totalTracked: 0,
    totalTrackedSuccess: 0,
    questionStatistics: [],
}

export const denormalizeStatistics = ({bookId, part, profileId, history}: Statistics): RawStatistics => ({
    book_id: bookId,
    history: JSON.stringify(history),
    part_id: part,
    profile_id: profileId,
})

export const normalizeStatistics = (rawStatistics: RawStatistics): Statistics => ({
    bookId: rawStatistics.book_id,
    part: rawStatistics.part_id,
    profileId: rawStatistics.profile_id,
    history: JSON.parse(rawStatistics.history || '{}') as History,
})

export const calculateStatistics = (tempStatistics: TempStatistics, existStatistics?: Statistics): Statistics => {
    const {
        profileId, bookId, part, history: newHistory, totalTime: bookLength,
        sessionWordsCount, questionStatistics, mode, sessionDuration, trackingData,
    } = tempStatistics

    const {history: existHistory = defaultHistory} = existStatistics || {}

    const totalReadingTime = newHistory.at(-1)?.audioTime || 0
    const totalTracked = Object.values(trackingData)
        .filter(String)
        .length
    const totalTrackedSuccess = Object.values(trackingData)
        .filter(status => status === 'success')
        .length

    const checkAnswers = newHistory
        .filter(({actionData}) => actionData?.type === 'check')
        .map(({actionResult, actionTime}) => ({
            success: actionResult === 'success',
            time: actionTime || 0,
        }))

    let followed = 0
    let succeedFollowed = 0

    newHistory.forEach(({audioTime = 0, touchedTime = 0}) => {
        followed++
        if (Math.abs(audioTime - touchedTime) < successDelta)
            succeedFollowed++
    })

    const calculatedStatistics = {
        profileId,
        bookId,
        part,
        history: {
            readingCheckTime: existHistory.readingCheckTime + (mode === 'check' ? sessionDuration : 0),
            readingHighlightTime: existHistory.readingHighlightTime + (mode === 'highlight' ? sessionDuration : 0),
            readingSoundTime: existHistory.readingSoundTime + (mode === 'sound' ? sessionDuration : 0),
            readingCheckWords: existHistory.readingCheckWords + (mode === 'check' ? sessionWordsCount : 0),
            readingHighlightWords: existHistory.readingHighlightWords + (mode === 'highlight' ? sessionWordsCount : 0),
            readingSoundWords: existHistory.readingSoundWords + (mode === 'sound' ? sessionWordsCount : 0),
            checkAnswers: [...existHistory.checkAnswers, ...checkAnswers],
            bookLength,
            readLength: Math.max(existHistory.readLength || totalReadingTime),
            totalTime: sessionDuration + existHistory.totalTime,
            totalWords: sessionWordsCount + existHistory.totalWords,
            totalFollowed: existHistory.totalFollowed + followed, //в тиках плеера
            totalFollowedSuccess: existHistory.totalFollowedSuccess + succeedFollowed, //в тиках плеера
            totalTracked: existHistory.totalTracked + totalTracked,
            totalTrackedSuccess: existHistory.totalTrackedSuccess + totalTrackedSuccess,
            questionStatistics: questionStatistics ? [...existHistory.questionStatistics, questionStatistics] : existHistory.questionStatistics,
        },
    }

    if (isDev)
        console.log('calculated statistics:', calculatedStatistics)

    return calculatedStatistics
}

export const useBookStatistics = (profileId?: string, bookId?: string) => {
    const {getBookStatistics: getIndexedBookStatistics} = useContext(StoreContext)
    const [statistics, setStatistics] = useState<ReturnType<typeof prepareStatistics>>()
    const [ready, setReady] = useState(true)

    useEffect(
        () => {
            if (!profileId || !bookId)
                return
            setReady(false)
            getIndexedBookStatistics(profileId, bookId)
                .then((statistics = []) => {
                    setStatistics(prepareStatistics(mergeStatistics(statistics)))
                })
                .finally(() => setReady(true))
        },
        [profileId, bookId, getIndexedBookStatistics]
    )

    return [statistics, ready] as const
}

export const getBookStatistics = (profileId: string, bookId: string) =>
    http.get<RawStatistics[]>(`${BACKEND}/statistics/${profileId}/${bookId}`)
        .then(statistics => statistics.map(normalizeStatistics))

export const deleteBookStatistics = (profileId: string, bookId: string) =>
    http.delete(`${BACKEND}/statistics/${profileId}/${bookId}`)

export const deletePartStatistics = (profileId: string, bookId: string, partId: string) =>
    http.delete(`${BACKEND}/statistics/${profileId}/${bookId}/${partId}`)

export const updateBookStatistics = (data: RawStatistics) =>
    http.post(`${BACKEND}/statistics`, data)
        .catch(checkShouldSync)

export const mergeStatistics = (statistics: Statistics[]) => {
    if (!statistics.length)
        return undefined

    const [mergedStatistics] = structuredClone(statistics)
    const mergedHistory = structuredClone(defaultHistory)

    statistics.forEach(({history}) => {
        mergedHistory.totalWords += history?.totalWords || 0
        mergedHistory.totalFollowedSuccess += history?.totalFollowedSuccess || 0
        mergedHistory.totalFollowed += history?.totalFollowed || 0

        mergedHistory.readingCheckTime += history?.readingCheckTime || 0
        mergedHistory.readingHighlightTime += history?.readingHighlightTime || 0
        mergedHistory.readingSoundTime += history?.readingSoundTime || 0
        mergedHistory.readingCheckWords += history?.readingCheckWords || 0
        mergedHistory.readingHighlightWords += history?.readingHighlightWords || 0
        mergedHistory.readingSoundWords += history?.readingSoundWords || 0
        mergedHistory.totalTracked += history?.totalTracked || 0
        mergedHistory.totalTrackedSuccess += history?.totalTrackedSuccess || 0

        mergedHistory.questionStatistics = [...mergedHistory.questionStatistics, ...history?.questionStatistics || []]
        mergedHistory.checkAnswers = [...mergedHistory.checkAnswers, ...history?.checkAnswers || []]
    })

    mergedStatistics.history = mergedHistory
    return mergedStatistics
}

export const prepareStatistics = (statistics?: Statistics) => {
    if (!statistics?.history)
        return undefined

    const {history} = statistics
    const checkAnswers = calculateCheckAnswers(history.checkAnswers)
    const assistantQuestions = calculateAssistantQuestions(history.questionStatistics)

    return {
        readingCheckTime: formatSeconds(history.readingCheckTime),
        readingHighlightTime: formatSeconds(history.readingHighlightTime),
        readingSoundTime: formatSeconds(history.readingSoundTime),
        readingCheckWords: history.readingCheckWords,
        readingHighlightWords: history.readingHighlightWords,
        readingSoundWords: history.readingSoundWords,
        totalTracked: history.totalTracked,
        totalTrackedSuccess: history.totalTrackedSuccess,
        answersTotal: checkAnswers.total || 0,
        answersSuccess: checkAnswers.success || 0,
        readLength: formatSeconds(history.readLength),
        bookLength: formatSeconds(history.bookLength),
        totalListened: (history.readLength / history.bookLength * 100).toFixed(2),
        totalFollowed: formatSeconds((history.totalFollowed || 0) * audioDiscretization),
        totalFollowedSuccess: formatSeconds((history.totalFollowedSuccess || 0) * audioDiscretization),
        followedSuccess: Math.floor(history.totalFollowedSuccess / history.totalFollowed * 100),
        totalWords: history.totalWords,
        assistantQuestionsSuccess: assistantQuestions.success,
        assistantQuestionsSkipped: assistantQuestions.skipped,
        assistantQuestionsTotal: assistantQuestions.total,
    }
}

const calculateCheckAnswers = (answers: CheckAnswer[]) => ({
    success: answers.reduce((sum, item) => item.success ? sum + 1 : sum, 0),
    total: answers.length,
})

const calculateAssistantQuestions = (questionsStatistics: QuestionStatistics[]) => ({
    success: questionsStatistics.reduce((sum, {correct}) => +correct + sum, 0),
    skipped: questionsStatistics.reduce((sum, {skip}) => +skip + sum, 0),
    total: questionsStatistics.reduce((sum, {total}) => +total + sum, 0),
})

const formatSeconds = (millis = 0) => (millis / 1000).toLocaleString(undefined, {maximumFractionDigits: 0})

const audioDiscretization = 200

export const formatPercent = (value: number, round?: boolean) => isNaN(value)
    ? '—'
    : `${round ? Math.round(value * 100) : Math.floor(value * 100)}%`
